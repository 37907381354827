import { styled } from '@f8n-frontend/stitches';
import { pick } from 'ramda';
import { ReactNode } from 'react';

import SpinnerStroked from 'components/SpinnerStroked';
import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import SearchResultsGrid from 'components/search/SearchResultsGrid';
import { MOMENT_BRANDING } from 'copy/branding';

import useSegmentEvent from 'hooks/analytics/use-segment-event';
import { notEmptyOrNil } from 'utils/helpers';

import { GlobalSearchResults, SearchEntity } from 'types/Search';

import SearchEmptyState, { EmptyStateContainer } from './SearchEmptyState';
import SearchHitArtwork from './SearchHitArtwork';
import SearchHitCollection from './SearchHitCollection';
import SearchHitMoment from './SearchHitMoment';
import SearchHitUser from './SearchHitUser';
import SearchHitWorld from './SearchHitWorld';
import ResultSection from './search-result/ResultSection';

const SearchPane = styled(Box, {
  position: 'absolute',
  left: 0,
  top: '$2',
  zIndex: 1000,
  width: 'calc(100vw - 48px)',

  '@bp2': {
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: '$searchInput',
  },
});

type SearchPayload = {
  searchInput: string;
  selectedResultType: SearchEntity;
  selectedResult: Record<string, unknown>;
};

interface SearchBarResultsProps {
  currentValue: string;
  results: GlobalSearchResults;
  isLoading: boolean;
}

export default function SearchBarResults(props: SearchBarResultsProps) {
  const { currentValue, results, isLoading } = props;
  const sendSegmentEvent = useSegmentEvent();
  const hasResults = notEmptyOrNil(results);

  if (isLoading) {
    return (
      <SearchBoxContainer>
        <Flex css={{ minHeight: 360 }} center>
          <SpinnerStroked size={32} />
        </Flex>
      </SearchBoxContainer>
    );
  }

  const noResultsFound = Object.values(results).every(
    (result) => result.items.length === 0
  );

  if (noResultsFound) {
    return (
      <SearchBoxContainer>
        <EmptyStateContainer>
          <SearchEmptyState
            heading="No search results"
            description={`There are no search results for ‘${currentValue}’`}
            headingSize={4}
          />
        </EmptyStateContainer>
      </SearchBoxContainer>
    );
  }

  return (
    <SearchBoxContainer>
      {hasResults && (
        <>
          <ResultSection
            title="Galleries"
            visible={results.worlds.items.length > 0}
          >
            {results.worlds.items.map((world) => {
              return (
                <SearchHitWorld
                  key={world.id}
                  world={world}
                  onClick={(world) =>
                    sendSegmentEvent<SearchPayload>({
                      eventName: 'global_search',
                      payload: {
                        searchInput: currentValue,
                        selectedResultType: 'world',
                        selectedResult: {
                          name: world.name,
                        },
                      },
                    })
                  }
                />
              );
            })}
          </ResultSection>
          <ResultSection
            title={MOMENT_BRANDING.plural}
            visible={results.moments.items.length > 0}
          >
            {results.moments.items.map((moment) => {
              return (
                <SearchHitMoment
                  key={moment.id}
                  moment={moment}
                  onClick={(moment) =>
                    sendSegmentEvent<SearchPayload>({
                      eventName: 'global_search',
                      payload: {
                        searchInput: currentValue,
                        selectedResultType: 'moment',
                        selectedResult: pick(['name', 'id'], moment),
                      },
                    })
                  }
                />
              );
            })}
          </ResultSection>
          <ResultSection
            title="Collections"
            visible={results.collections.items.length > 0}
          >
            {results.collections.items.map((collection) => {
              return (
                <SearchHitCollection
                  key={collection.objectID}
                  collection={collection}
                  onClick={(collection) =>
                    sendSegmentEvent<SearchPayload>({
                      eventName: 'global_search',
                      payload: {
                        searchInput: currentValue,
                        selectedResultType: 'collection',
                        selectedResult: pick(
                          ['symbol', 'contractAddress', 'name'],
                          collection
                        ),
                      },
                    })
                  }
                />
              );
            })}
          </ResultSection>
          <ResultSection
            title="NFTs"
            visible={results.artworks.items.length > 0}
          >
            {results.artworks.items.map((artwork) => {
              return (
                <SearchHitArtwork
                  key={artwork.objectID}
                  hit={artwork}
                  onClick={(artwork) =>
                    sendSegmentEvent<SearchPayload>({
                      eventName: 'global_search',
                      payload: {
                        searchInput: currentValue,
                        selectedResultType: 'artwork',
                        selectedResult: pick(
                          ['name', 'tokenId', 'contractAddress'],
                          artwork
                        ),
                      },
                    })
                  }
                />
              );
            })}
          </ResultSection>
          <ResultSection
            title="Profiles"
            visible={results.users.items.length > 0}
          >
            {results.users.items.map((user) => {
              return (
                <SearchHitUser
                  key={user.objectID}
                  user={user}
                  onClick={(user) =>
                    sendSegmentEvent<SearchPayload>({
                      eventName: 'global_search',
                      payload: {
                        searchInput: currentValue,
                        selectedResultType: 'user',
                        selectedResult: pick(
                          ['publicKey', 'username', 'name'],
                          user
                        ),
                      },
                    })
                  }
                />
              );
            })}
          </ResultSection>
        </>
      )}
    </SearchBoxContainer>
  );
}

interface SearchBoxContainerProps {
  children: ReactNode;
}

function SearchBoxContainer(props: SearchBoxContainerProps) {
  const { children } = props;
  return (
    <SearchPane>
      <SearchResultsGrid
        css={{
          gap: '$2',
          borderRadius: '$4',
          boxShadow: '$regular1',
          '@bp1': {
            boxShadow: '$regular2',
          },
        }}
      >
        {children}
      </SearchResultsGrid>
    </SearchPane>
  );
}
