import { ReactNode } from 'react';

import Heading from 'components/base/Heading';
import Mono from 'components/base/Mono';
import Text from 'components/base/Text';

interface SearchResultSubheadingProps {
  isMono: boolean;
  children: ReactNode;
}

export default function SearchResultSubheading(
  props: SearchResultSubheadingProps
) {
  const { isMono, children } = props;
  return (
    <Heading as="h3" color="dim" css={{ display: 'flex' }} lineHeight={1}>
      {isMono ? (
        <Mono size={1}>{children}</Mono>
      ) : (
        <Text size={1}>{children}</Text>
      )}
    </Heading>
  );
}
