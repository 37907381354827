import { compose, when, allPass, last, includes, split, ifElse } from 'ramda';

import { ArtworkFragmentExtended } from 'gql/hasura/hasura-fragments.generated';
import { FND_CONTRACT_SLUG } from 'lib/constants';
import { getUsernameOrAddress, isString, notEmptyOrNil } from 'utils/helpers';

import { UserLight } from 'types/Account';
import { AssetStatus } from 'types/Artwork';
import { Collection } from 'types/Collection';
import { Maybe } from 'types/utils';

type ArtworkMeta = {
  id: string;
  tokenId: Maybe<number>;
  name: Maybe<string>;
  contractAddress: Maybe<string>;
  collection: Maybe<{
    slug: string | null;
  }>;
};

interface BuildArtworkPathProps {
  // TODO (types): remove null | undefined here. It's too loose, but avoids a lot of TS errors in consumers.
  // remove it after moving this logic up to the transform layer
  user: Partial<UserLight> | null | undefined;
  artwork: Partial<ArtworkMeta>;
  collection?: Pick<Collection, 'slug'>;
}
export function buildArtworkPath({
  artwork,
  collection,
  user,
}: BuildArtworkPathProps): string {
  if (!user) return '';
  if (!artwork) return '';

  const username = getUsernameOrAddress(user);
  const tokenId = artwork?.tokenId;
  const contractSlug = collection
    ? collection.slug
    : getArtworkCollectionSlug(artwork as ArtworkMeta);
  return `/${username}/${contractSlug}/${tokenId}`;
}

interface BuildUserProfilePathProps {
  user: UserLight;
}
export function buildUserProfilePath({
  user,
}: BuildUserProfilePathProps): string {
  const username = getUsernameOrAddress(user);
  return `/${username}`;
}

export const hasActiveBuyNow = <T extends ArtworkFragmentExtended>(
  artwork: T
): boolean => {
  return artwork?.buyNows?.some((buy) => buy.status === 'OPEN');
};

function getArtworkCollectionSlug(artwork: ArtworkMeta) {
  return artwork?.collection?.slug ?? FND_CONTRACT_SLUG;
}

const getStrAfterLastHyphen = when(isString, compose(last, split('-')));

const containsHyphen = includes('-');

export const getTokenId: (arg0: string) => string = ifElse(
  allPass([notEmptyOrNil, containsHyphen]),
  getStrAfterLastHyphen,

  () => null
);

/**
 * @deprecated use getLastUrlPathSegment for improve type and runtime safety
 */
export const getStrAfterLastSlash = when(isString, compose(last, split('/')));

export const getNoAssetArtworkLabel = () => {
  return 'Unable to render content';
};

export const getNoAssetArtworkUrl = <
  Collection extends {
    coverImageUrl?: Maybe<string>;
    collectionImageUrl?: Maybe<string>;
  },
>(
  collection: Collection = {} as Collection
): string | undefined => {
  return (
    collection?.coverImageUrl || collection?.collectionImageUrl || undefined
  );
};

export const isUnsupportedArtworkAsset = <
  Artwork extends { assetStatus: AssetStatus },
>({
  assetStatus,
}: Artwork): boolean => {
  return assetStatus === 'FAILED';
};
