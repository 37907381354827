import {
  ActivityIcon,
  BurgerIcon,
  CloseIcon,
  DropCollectionIcon,
  ExhibitionIcon,
  FeedIcon,
  GridIcon,
  NftIcon,
  SearchIcon,
  TrendingIcon,
} from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

import StatusDot from 'components/StatusDot';
import Body from 'components/base/Body';
import Button from 'components/base/Button';
import DropdownMenu from 'components/base/DropdownMenu';
import Text from 'components/base/Text';
import ToggleButton from 'components/base/ToggleButton';
import CreateModal from 'components/create-flow/CreateModal';
import ConnectWalletButton from 'components/headers/ConnectWalletButton';
import ExploreDropdownMenu from 'components/headers/ExploreDropdownMenu';
import HeaderButton from 'components/headers/HeaderButton';
import UserDropdown from 'components/headers/UserDropdown';
import LogoLink from 'components/links/LogoLink';
import SearchBar from 'components/search/SearchBar';
import useAnalytics from 'contexts/analytics/useAnalytics';
import {
  hasPublicKey,
  hasUser,
  isAdmin,
  isPending,
} from 'contexts/auth/helpers';
import { AuthConnected, AuthEnriched } from 'contexts/auth/types';
import useAuth from 'contexts/auth/useAuth';

import { useHasWorldNotifications } from 'gql/api/queries/has-world-notifications.generated';
import { useWorldInfoBySlug } from 'gql/api/queries/world-info-by-id.generated';
import useWorldRole from 'hooks/queries/api/use-world-role';
import useWorldDashboard from 'hooks/queries/api/useWorldDashboard';
import useUserActivityCount from 'hooks/queries/hasura/notifications/use-user-activity-count';
import { useIsHydrated } from 'hooks/use-is-hydrated';
import { ACTIVITY_PATH, FEED_PATH } from 'lib/constants';
import { isNumberType } from 'utils/helpers';
import { GLOBAL_NAV_HEIGHT } from 'utils/layout';
import { getPath } from 'utils/router';

import { PageColorMode, PageType } from 'types/page';

import UserDropdownGuard, {
  HeaderLoadingUserButton,
} from './UserDropdownGuard';

interface HeaderProps {
  absolute: boolean;
  mode: PageColorMode;
  type: PageType;
}

function HeaderBase(props: HeaderProps) {
  const { absolute, mode, type } = props;

  const auth = useAuth();

  const isHydrated = useIsHydrated();

  const hasDarkBackground = mode === 'DARK';
  const logoColor = hasDarkBackground ? '#fff' : '#000';
  const [searchOpen, setSearchOpen] = useState(false);

  const router = useRouter();
  const currentPath = router.pathname;

  if (type === 'MINIMAL_LOGGED_IN') {
    return (
      <Root absolute={absolute}>
        <LogoLink color={logoColor} />
        <UserDropdownGuard hasDarkBackground={hasDarkBackground} />
      </Root>
    );
  }

  if (type === 'MINIMAL_LOGGED_IN_USER_MENU') {
    return (
      <Root absolute css={{ justifyContent: 'end', maxWidth: '100%' }}>
        <UserDropdownGuard hasDarkBackground={hasDarkBackground} />
      </Root>
    );
  }

  const getHeader = () => {
    if (isPending(auth) || !isHydrated) {
      return <HeaderLoadingUserButton hasDarkBackground={hasDarkBackground} />;
    } else if (hasUser(auth) || hasPublicKey(auth)) {
      return (
        <AuthHeader
          auth={auth}
          hasDarkBackground={hasDarkBackground}
          searchOpen={searchOpen}
          setSearchOpen={setSearchOpen}
        />
      );
    } else {
      return (
        <DefaultHeader>
          <ConnectWalletButtonWrapper>
            <ConnectWalletButton
              auth={auth}
              hasDarkBackground={hasDarkBackground}
              size={0}
            />
          </ConnectWalletButtonWrapper>
          <MobileSearchTrigger
            hasDarkBackground={hasDarkBackground}
            isOpen={searchOpen}
            onClick={() => setSearchOpen(!searchOpen)}
          />
          {!searchOpen && (
            <MobileDropdown hasDarkBackground={hasDarkBackground} />
          )}
        </DefaultHeader>
      );
    }
  };

  return (
    <Root absolute={absolute}>
      <HeaderCell>
        {!searchOpen && <LogoLink color={logoColor} />}
        <LeftButtonContainer>
          <NextLink href={FEED_PATH} passHref>
            <HeaderButton
              as="a"
              isDark={hasDarkBackground}
              isActive={currentPath === FEED_PATH}
            >
              Feed
            </HeaderButton>
          </NextLink>
          <ExploreDropdownMenu isDark={hasDarkBackground} />
        </LeftButtonContainer>
      </HeaderCell>
      <HeaderCell>
        <SearchBar
          pageColorMode={mode}
          searchOpen={searchOpen}
          setSearchOpen={setSearchOpen}
        />
      </HeaderCell>
      <HeaderCell>{getHeader()}</HeaderCell>
    </Root>
  );
}

/* Search button */
type MobileSearchTriggerProps = {
  hasDarkBackground: boolean;
  isOpen: boolean;
  onClick: () => void;
};

function MobileSearchTrigger(props: MobileSearchTriggerProps) {
  const { onClick, isOpen, hasDarkBackground } = props;
  return (
    <ToggleButton
      aria-label={isOpen ? 'Close search' : 'Open search'}
      css={{ '@bp2': { display: 'none' } }}
      icon="standalone"
      onClick={onClick}
      size={0}
      type="button"
      variant={hasDarkBackground ? 'blur' : 'outline'}
    >
      {isOpen ? <CloseIcon /> : <SearchIcon />}
    </ToggleButton>
  );
}

/* Mobile burger navigation */
type MobileDropdownProps = {
  hasDarkBackground?: boolean;
};

function MobileDropdown(props: MobileDropdownProps) {
  const { hasDarkBackground } = props;
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Button
          aria-label="Menu"
          size={0}
          icon="standalone"
          variant={hasDarkBackground ? 'blur' : 'outline'}
          css={{ display: 'flex', '@bp2': { display: 'none' } }}
        >
          <BurgerIcon />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        <DropdownMenu.Item type="link" href="/feed">
          <FeedIcon size={2} />
          <BiggerText>Feed</BiggerText>
        </DropdownMenu.Item>
        <DropdownMenu.Item type="link" href={getPath.trending.drops}>
          <DropCollectionIcon size={2} />
          <BiggerText>Drops</BiggerText>
        </DropdownMenu.Item>
        <DropdownMenu.Item type="link" href={getPath.trending.galleries}>
          <GridIcon size={2} />
          <BiggerText>Galleries</BiggerText>
        </DropdownMenu.Item>
        <DropdownMenu.Item type="link" href={getPath.trending.exhibitions}>
          <ExhibitionIcon size={2} />
          <BiggerText>Exhibitions</BiggerText>
        </DropdownMenu.Item>
        <DropdownMenu.Item type="link" href={getPath.trending.editions}>
          <TrendingIcon size={2} />
          <BiggerText>Editions</BiggerText>
        </DropdownMenu.Item>
        <DropdownMenu.Item type="link" href="/nfts">
          <NftIcon size={2} />
          <BiggerText>NFTs</BiggerText>
        </DropdownMenu.Item>
        <ButtonWrapper>
          <CreateButtonWrapper>
            <CreateModal buttonSize={1} />
          </CreateButtonWrapper>
        </ButtonWrapper>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}

interface AuthHeaderProps {
  auth: AuthConnected | AuthEnriched;
  hasDarkBackground: boolean;
  searchOpen: boolean;
  setSearchOpen(searchOpen: boolean): void;
}

function AuthHeader(props: AuthHeaderProps) {
  const { auth, hasDarkBackground, searchOpen, setSearchOpen } = props;
  const { publicKey } = auth;

  const { data: activityCount } = useUserActivityCount({ publicKey });
  const hasWorldsQuery = useWorldDashboard({ publicKey });

  const worldId = hasWorldsQuery.data?.id;

  return (
    <AuthHeaderRoot>
      <HideOnMobile>
        <NextLink href={ACTIVITY_PATH} passHref>
          <Button
            as="a"
            aria-label="Activity"
            variant={hasDarkBackground ? 'blur' : 'secondary'}
            size={0}
            icon="standalone"
          >
            <ActivityIcon />
            {typeof activityCount === 'number' && activityCount > 0 && (
              <StatusDot
                color="red"
                css={{ position: 'absolute', top: -2, right: 3 }}
              />
            )}
          </Button>
        </NextLink>
      </HideOnMobile>
      <DashboardButton
        auth={auth}
        worldId={worldId}
        hasDarkBackground={hasDarkBackground}
      />
      <HideOnMobile>
        <CreateModal isDark={hasDarkBackground} />
      </HideOnMobile>
      {!searchOpen && (
        <UserDropdown auth={auth} hasDarkBackground={hasDarkBackground} />
      )}
      <MobileSearchTrigger
        hasDarkBackground={hasDarkBackground}
        isOpen={searchOpen}
        onClick={() => setSearchOpen(!searchOpen)}
      />
      {!searchOpen && <MobileDropdown hasDarkBackground={hasDarkBackground} />}
    </AuthHeaderRoot>
  );
}

const HeaderStatusDot = styled(StatusDot, {
  position: 'absolute',
  top: -2,
  right: 3,
});

HeaderStatusDot.defaultProps = {
  color: 'red',
};

interface DashboardButtonProps {
  hasDarkBackground: boolean;
  worldId?: number;
  auth: AuthConnected | AuthEnriched;
}

function DashboardButton(props: DashboardButtonProps) {
  const { worldId, hasDarkBackground, auth } = props;
  if (worldId) {
    return (
      <DashboardButtonConnected
        worldId={worldId}
        hasDarkBackground={hasDarkBackground}
        auth={auth}
      />
    );
  }
  return null;
}

/**
 * This nav button is only displayed to worldbuilders
 * checks if user is on a world page & if it is their world
 * if so, set nav dashboard link to current world
 */
function DashboardButtonConnected(
  props: DashboardButtonProps & {
    worldId: number;
  }
) {
  const { worldId: newestWorldId, hasDarkBackground, auth } = props;
  const analytics = useAnalytics();
  const router = useRouter();
  const isOnWorld = router.pathname.startsWith('/world/');
  const localWorldSlug = isOnWorld ? router.query.slug : undefined;
  const localWorldQuery = useWorldInfoBySlug(
    { slug: localWorldSlug as string },
    {
      enabled: isOnWorld && Boolean(localWorldSlug),
      select: (data) => {
        if (!data.world) return null;
        return {
          worldId: data.world.id,
        };
      },
    }
  );

  const localWorldId = localWorldQuery.data
    ? localWorldQuery.data.worldId
    : null;

  const targetWorldId = isNumberType(localWorldId)
    ? localWorldId
    : newestWorldId;

  const worldRoleQuery = useWorldRole({
    publicKey: auth.publicKey,
    worldId: targetWorldId,
  });

  const worldRole = worldRoleQuery.isSuccess ? worldRoleQuery.data : null;

  const isWorldTeamMember = worldRole !== null;

  const isTeamMemberOrAdmin = isAdmin(auth) || isWorldTeamMember;

  const href =
    isOnWorld && isTeamMemberOrAdmin && isNumberType(localWorldId)
      ? getPath.world.dashboard(localWorldId)
      : getPath.world.dashboard(newestWorldId);

  const hasWorldsNotificationsQuery = useHasWorldNotifications({
    publicKey: auth.publicKey,
  });

  const hasWorldsNotifications = Boolean(
    hasWorldsNotificationsQuery.data?.hasWorldNotifications
  );

  return (
    <HideOnMobile
      onClick={() =>
        analytics.track({
          name: 'clicked_nav_dashboard',
          hasDot: hasWorldsNotifications,
        })
      }
    >
      <NextLink href={href} passHref>
        <Button
          as="a"
          variant={hasDarkBackground ? 'blur' : 'secondary'}
          icon
          size={0}
        >
          <GridIcon />
          <span>Dashboard</span>
          {hasWorldsNotifications && <HeaderStatusDot />}
        </Button>
      </NextLink>
    </HideOnMobile>
  );
}

const Root = styled(Body, {
  flex: 0,
  left: 0,
  right: 0,
  zIndex: 20,
  width: '100%',
  paddingY: '$2',
  paddingX: '$4',
  display: 'flex',
  minHeight: GLOBAL_NAV_HEIGHT,
  alignItems: 'center',
  justifyContent: 'space-between',

  '@bp2': {
    paddingX: '$8',
  },

  variants: {
    absolute: {
      true: {
        position: 'absolute',
      },
      false: {
        position: 'relative',
      },
    },
  },
  defaultVariants: {
    absolute: false,
  },
});

const HeaderCell = styled('div', {
  '&:not(:first-child):not(:last-child)': {
    flex: 1,
    display: 'flex',
  },
  '@bp2': {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    '&:first-child': {
      justifyContent: 'flex-start',
    },
    '&:not(:first-child):not(:last-child)': {
      justifyContent: 'center',
    },
    '&:last-child': {
      justifyContent: 'flex-end',
    },
  },
});

const LeftButtonContainer = styled('div', {
  display: 'none',
  position: 'relative',
  marginLeft: '$5',
  '@bp2': {
    display: 'flex',
    justifyContent: 'center',
  },
});

const AuthHeaderRoot = styled('div', {
  gap: '$3',
  display: 'flex',
});

const HideOnMobile = styled('div', {
  display: 'none',
  '@bp2': {
    display: 'flex',
  },
});

const DefaultHeader = styled('div', {
  gap: '$3',
  display: 'flex',
  alignItems: 'center',
});

const ConnectWalletButtonWrapper = styled('div', {
  display: 'none',
  '@bp2': {
    display: 'flex',
  },
});

const BiggerText = styled(Text, {});

BiggerText.defaultProps = {
  size: 3,
};

const ButtonWrapper = styled('div', {
  paddingX: '$2',
  paddingY: '$4',
  display: 'flex',
});

const CreateButtonWrapper = styled('div', {
  width: '100%',
  [`${Button}`]: { width: '100%' },
});

const Header = Object.assign(HeaderBase, {
  Root,
});

export default Header;
