import { styled } from '@f8n-frontend/stitches';
import { keepPreviousData } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState, memo } from 'react';
import { useClickAway } from 'react-use';

import Box from 'components/base/Box';
import { HeaderProps } from 'components/headers/types';

import useGlobalSearch from 'hooks/queries/use-global-search';
import { isAllTrue, notEmptyOrNil } from 'utils/helpers';

import { PageColorMode } from 'types/page';

import SearchBarInput from './SearchBarInput';
import SearchBarOverlay from './SearchBarOverlay';
import SearchBarResults from './SearchBarResults';

const SearchContainer = styled(Box, {
  flex: 1,
  position: 'relative',
  justifyContent: 'center',
  marginRight: '$3',
  '@bp2': {
    marginRight: 0,
    maxWidth: '$searchInput',
  },
  '@bp3': {
    width: '$searchInput',
  },
});

interface SearchBarProps extends HeaderProps {
  pageColorMode: PageColorMode;
}

export default memo(SearchBar);

function SearchBar(props: SearchBarProps) {
  const { pageColorMode, searchOpen } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { data: searchData, isLoading: isSearchLoading } = useGlobalSearch(
    { searchTerm },
    {
      placeholderData: keepPreviousData,
      enabled: isAllTrue([isFocused, notEmptyOrNil(searchTerm)]),
    }
  );

  const router = useRouter();

  useEffect(() => {
    setIsFocused(false);
  }, [router.asPath]);

  const ref = useRef(null);

  useClickAway(ref, () => {
    setIsFocused(false);
  });

  return (
    <>
      <SearchContainer
        ref={ref}
        css={{
          display: searchOpen ? 'block' : 'none',
          '@bp2': {
            display: 'block',
          },
        }}
      >
        <SearchBarInput
          colorMode={pageColorMode}
          isFocused={isFocused}
          placeholder="Search galleries, exhibitions, artists or works"
          searchOpen={searchOpen}
          searchTerm={searchTerm}
          setIsFocused={setIsFocused}
          setSearchTerm={setSearchTerm}
          title="Search galleries, exhibitions, artists or works"
        />
        <Box css={{ position: 'relative' }}>
          {isFocused && searchTerm && searchData && (
            <SearchBarResults
              currentValue={searchTerm}
              results={searchData}
              isLoading={isSearchLoading}
            />
          )}
        </Box>
      </SearchContainer>
      <SearchBarOverlay
        css={{
          transform: 'translate3d(0, 0, 0)',
          opacity: isFocused ? 1 : 0,
          pointerEvents: isFocused ? 'all' : 'none',
          display: 'none',
          '@bp2': {
            display: 'block',
          },
        }}
      />
    </>
  );
}
