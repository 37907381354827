import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { match } from 'ts-pattern';

import { useIsHydrated } from 'hooks/use-is-hydrated';
import {
  modelAssetsHost,
  videoAssetsHost,
  imageAssetsHost,
  modelImageAssetsHost,
  imgixProxyOrigin,
} from 'lib/assets';
import { getFaviconHref, getFaviconNames } from 'utils/favicon';
import { buildOgTags } from 'utils/og-tags';
import { withHttps } from 'utils/urls';

interface PageHeadProps {
  description?: string | null;
  /** if `null`, `og:image` and `twitter:image` meta tags are not included  */
  image?: string | null;
  title?: string | null;
}

const favicons = getFaviconNames();
const FALLBACK_DESCRIPTION = 'The start of something new';
const FALLBACK_OG_IMAGE = 'https://foundation.app/opengraph.jpg';

export default function PageHead(props: PageHeadProps) {
  const { description, title, image } = props;

  const [faviconName, setFaviconName] = useState(favicons.light);
  const isHydrated = useIsHydrated();
  const { asPath } = useRouter();

  useEffect(() => {
    if (!window.matchMedia) return;

    const media = window.matchMedia('(prefers-color-scheme: dark)');

    if (typeof media.addEventListener !== 'function') return;

    setFaviconName(media.matches ? favicons.dark : favicons.light);

    const updateFavicon = (event: MediaQueryListEvent) => {
      setFaviconName(event.matches ? favicons.dark : favicons.light);
    };

    media.addEventListener('change', updateFavicon);
    return () => {
      media.removeEventListener('change', updateFavicon);
    };
  }, [isHydrated]);

  const getPageTitle = () => {
    if (title && asPath.startsWith('/manage/') && typeof title === 'string') {
      return title;
    }
    return title ? `${title} | Foundation` : 'Foundation';
  };

  const pageTitle = getPageTitle();
  const pageDescription = description ? description : FALLBACK_DESCRIPTION;
  const ogImage = match(image)
    .with(undefined, () => FALLBACK_OG_IMAGE)
    .with(null, () => null)
    .otherwise(() => withHttps(image));
  const metaTags = buildOgTags({ pageTitle, pageDescription, asPath, ogImage });

  return (
    <Head>
      <meta charSet="utf-8" />
      <title>{pageTitle}</title>

      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {metaTags.map((tag) =>
        tag.content ? (
          <meta
            name={tag.name}
            property={tag.property}
            content={tag.content}
            key={tag?.name ?? tag?.property}
          />
        ) : null
      )}

      <meta property="og:type" content="website" />

      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="theme-color" content="#ffffff" />

      {/**
       * this stops numbers being recognised as phone numbers on mobile
       * @see http://www.html-5.com/metatags/format-detection-meta-tag.html
       */}
      <meta name="format-detection" content="telephone=no" />

      <link rel="manifest" href="/site.webmanifest" />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={getFaviconHref(32, faviconName)}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={getFaviconHref(32, faviconName)}
      />

      {/* 128px shortcut icon for metamask */}
      <link rel="shortcut icon" type="image/png" href="/icon-metamask.png" />

      <link rel="preconnect" href={videoAssetsHost} />
      <link rel="preconnect" href={modelAssetsHost} />
      <link rel="preconnect" href={modelImageAssetsHost} />
      <link rel="preconnect" href={imageAssetsHost} />
      <link rel="preconnect" href={imgixProxyOrigin} />
    </Head>
  );
}
