import NextLink from 'next/link';

import Avatar from 'components/base/Avatar';
import Grid from 'components/base/Grid';

import { buildUserProfilePath } from 'utils/artwork/artwork';
import { getUsernameOrTruncatedAddress, hasUsername } from 'utils/helpers';

import type { AlgoliaUser } from 'types/Algolia';

import SearchResultElement from './search-result/SearchResultElement';
import SearchResultHeading from './search-result/SearchResultHeading';
import SearchResultSubheading from './search-result/SearchResultSubheading';

interface SearchHitUserProps {
  user: AlgoliaUser;
  onClick: (arg0: AlgoliaUser) => void;
}

export default function SearchHitUser(props: SearchHitUserProps) {
  const { user, onClick } = props;

  const usernameOrTruncatedAddress = getUsernameOrTruncatedAddress(user);
  const userHasUsername = hasUsername(user);

  return (
    <NextLink href={buildUserProfilePath({ user })} prefetch={false} passHref>
      <SearchResultElement as="a" onClick={() => onClick(user)}>
        <Avatar
          imageUrl={user.profileImageUrl}
          css={{ marginRight: '$4' }}
          size={4}
        />
        <Grid css={{ alignItems: 'center', gap: '$1' }}>
          {user.name && <SearchResultHeading>{user.name}</SearchResultHeading>}
          <SearchResultSubheading isMono={!userHasUsername}>
            {usernameOrTruncatedAddress}
          </SearchResultSubheading>
        </Grid>
      </SearchResultElement>
    </NextLink>
  );
}
