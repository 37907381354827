import { styled } from '@f8n-frontend/stitches';
import { ComponentProps } from '@stitches/react';

import { primaryButtonCss } from 'css/button';

import Button from './Button';
import { ButtonFilterIndicatorRoot } from './ButtonFilterIndicator';

type ChevronButtonProps = ComponentProps<typeof ChevronButton>;

const EXPANDED_SELECTOR = '&[aria-expanded="true"]';

const expandedCss = {
  [EXPANDED_SELECTOR]: {
    ...primaryButtonCss,
    '&:after': {
      backgroundColor: '$white100',
    },
  },
};

const ChevronButton = styled(Button, {
  '&:after': {
    top: '50%',
    content: '""',
    position: 'absolute',

    display: 'block',
    transform: 'translateY(-50%)',
  },
  '&:disabled': {
    '&:after': {
      backgroundColor: 'currentColor',
    },
  },

  [`${EXPANDED_SELECTOR} ${ButtonFilterIndicatorRoot}`]: {
    opacity: 0,
    transform: 'scale3d(0,0,0)',
  },

  variants: {
    variant: {
      base: {},
      outline: expandedCss,
      raised: {
        [EXPANDED_SELECTOR]: {
          backgroundColor: '$black5',
          boxShadow: 'none',
          '@hover': {
            '&:hover': {
              transform: 'translate3d(0, -1px, 0)',
              boxShadow: 'none',
              '&:active': {
                transform: 'translate3d(0, 1px, 0)',
              },
            },
          },
        },
      },
      ghost: {
        [EXPANDED_SELECTOR]: {
          backgroundColor: '$black5',
          '@hover': {
            '&:hover': {
              transform: 'translate3d(0, -1px, 0)',
              '&:active': {
                transform: 'translate3d(0, 1px, 0)',
              },
            },
          },
        },
      },
      blur: {
        '&:after': {
          backgroundColor: '$white100',
        },
        [`${EXPANDED_SELECTOR}, &:hover:not(:disabled)`]: {
          backgroundColor: '$white100',
          color: 'inherit',

          '&:after': {
            backgroundColor: '$black100',
          },
        },
      },
    },
    size: {
      0: {
        paddingRight: 32,
        '&:after': {
          width: 10,
          height: 10,
          right: 14,
          backgroundColor: '$black100',
          maskImage: 'url(/images/icons/chevron-small.svg)',
          transition: 'transform $0 $ease',
        },
      },
      1: {
        paddingRight: 40,
        '&:after': {
          width: 12,
          height: 12,
          right: 20,
          backgroundColor: '$black100',
          maskImage: 'url(/images/icons/chevron-medium.svg)',
          transition: 'transform $1 $ease',
        },
      },
      2: {
        paddingRight: 50,
        '&:after': {
          width: 14,
          height: 14,
          right: 24,
          backgroundColor: '$black100',
          maskImage: 'url(/images/icons/chevron-large.svg)',
          transition: 'transform $2 $ease',
        },
      },
    },
    animateIcon: {
      true: {
        [EXPANDED_SELECTOR]: {
          '&:after': {
            transform: 'translateY(-50%) rotate(180deg)',
          },
        },
      },
      false: {},
    },
  },
  defaultVariants: {
    animateIcon: true,
    variant: 'outline',
  },
});

export type { ChevronButtonProps };
export default ChevronButton;
