import Theme from 'components/Theme';
import Button, { ButtonVariants } from 'components/base/Button';
import { AuthDisconnected } from 'contexts/auth/types';

import { UnsafeAny } from 'types/utils';

interface ConnectWalletButtonProps {
  auth: AuthDisconnected;
  css?: UnsafeAny;
  hasDarkBackground?: boolean;
  size?: ButtonVariants['size'];
  variant?: ButtonVariants['variant'];
}

export default function ConnectWalletButton(props: ConnectWalletButtonProps) {
  const { auth, hasDarkBackground, size = 1, variant = 'primary', css } = props;

  return (
    <Theme theme={hasDarkBackground ? 'dark' : 'light'}>
      <Button size={size} variant={variant} onClick={auth.startLogin} css={css}>
        Connect
      </Button>
    </Theme>
  );
}
