import React from 'react';

import Spinner from 'components/Spinner';
import Button from 'components/base/Button';
import { hasUser, isPending } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import { useIsHydrated } from 'hooks/use-is-hydrated';

import ConnectWalletButton from './ConnectWalletButton';
import UserDropdown from './UserDropdown';

type UserDropdownGuardProps = {
  hasDarkBackground: boolean;
};

export default function UserDropdownGuard(props: UserDropdownGuardProps) {
  const { hasDarkBackground } = props;

  const auth = useAuth();
  const isHydrated = useIsHydrated();

  if (isPending(auth) || !isHydrated) {
    return <HeaderLoadingUserButton hasDarkBackground={hasDarkBackground} />;
  }

  if (hasUser(auth) || auth.state === 'connected') {
    return <UserDropdown auth={auth} hasDarkBackground={hasDarkBackground} />;
  }

  return (
    <ConnectWalletButton auth={auth} hasDarkBackground={hasDarkBackground} />
  );
}

type HeaderLoadingUserButtonProps = {
  hasDarkBackground: boolean;
};

export function HeaderLoadingUserButton(props: HeaderLoadingUserButtonProps) {
  return (
    <Button
      size={0}
      icon="standalone"
      variant={props.hasDarkBackground ? 'blur' : 'secondary'}
      disabled
    >
      <Spinner />
    </Button>
  );
}
