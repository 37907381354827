export const GIF_MIME_TYPE = 'image/gif' as const;
const SVG_MIME_TYPE = 'image/svg+xml' as const;

/** Static image mimeTypes that we support uploading */
const STATIC_IMAGE_UPLOAD_MIME_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
] as const;

/** Video mimeTypes that we support uploading */
const VIDEO_UPLOAD_MIME_TYPES = ['video/mp4', 'video/quicktime'] as const;

/** Model mimeTypes that we support uploading */
const MODEL_UPLOAD_MIME_TYPES = [
  'model/gltf-binary',
  'model/gltf+json',
] as const;

/** All mimeTypes that we support uploading */
export const UPLOAD_MIME_TYPES = [
  GIF_MIME_TYPE,
  SVG_MIME_TYPE,
  ...STATIC_IMAGE_UPLOAD_MIME_TYPES,
  ...VIDEO_UPLOAD_MIME_TYPES,
  ...MODEL_UPLOAD_MIME_TYPES,
] as const;

/** mimeTypes that we support uploading for Drops */
export const DROPS_UPLOAD_MIME_TYPES = [
  GIF_MIME_TYPE,
  SVG_MIME_TYPE,
  ...STATIC_IMAGE_UPLOAD_MIME_TYPES,
  ...VIDEO_UPLOAD_MIME_TYPES,
] as const;

/** mimeTypes that we support uploading for Editions */
export const EDITIONS_UPLOAD_MIME_TYPES = [
  GIF_MIME_TYPE,
  ...STATIC_IMAGE_UPLOAD_MIME_TYPES,
  ...VIDEO_UPLOAD_MIME_TYPES,
] as const;

/** Image mimeTypes that we support rendering */
export const IMAGE_RENDER_MIME_TYPES = [
  GIF_MIME_TYPE,
  SVG_MIME_TYPE,
  ...STATIC_IMAGE_UPLOAD_MIME_TYPES,
  'image/tiff',
] as const;

/** Video mimeTypes that we support rendering */
export const VIDEO_RENDER_MIME_TYPES = [
  ...VIDEO_UPLOAD_MIME_TYPES,
  'video/webm',
  'video/x-m4v',
] as const;

/** Model mimeTypes that we support rendering */
export const MODEL_RENDER_MIME_TYPES = MODEL_UPLOAD_MIME_TYPES;

/** All mimeTypes that we support rendering */
export const RENDER_MIME_TYPES = [
  ...IMAGE_RENDER_MIME_TYPES,
  ...VIDEO_RENDER_MIME_TYPES,
  ...MODEL_RENDER_MIME_TYPES,
];
