import {
  DropCollectionIcon,
  EditionCollectionIcon,
  ExhibitionIcon,
  GridIcon,
  NftIcon,
} from '@f8n/icons';
import {
  FloatingFocusManager,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useId,
  useInteractions,
  useListNavigation,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import NextLink from 'next/link';
import { useRef, useState } from 'react';

import Menu from 'components/Menu';
import Box from 'components/base/Box';
import Text from 'components/base/Text';

import { getPath } from 'utils/router';

import HeaderChevronButton from './HeaderChevronButton';

interface ExploreDropdownMenuProps {
  isDark: boolean;
}

function ExploreDropdownMenu(props: ExploreDropdownMenuProps) {
  const { isDark } = props;

  const backgroundColor = isDark ? '$white20' : '$black5';

  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<null | number>(null);
  const listRef = useRef<HTMLAnchorElement[]>([]);

  const { x, y, refs, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [offset(10), shift()],
  });

  const focus = useFocus(context);
  const hover = useHover(context, {
    handleClose: safePolygon(),
  });
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [dismiss, hover, focus, listNavigation, role]
  );

  const { isMounted, styles } = useTransitionStyles(context, {
    duration: 200,
    initial: {
      opacity: 0,
      transform: 'translateY(-8px)',
    },
    open: {
      opacity: 1,
      transform: 'translateY(0px)',
    },
    close: {
      opacity: 0,
      transform: 'translateY(-8px)',
    },
  });

  const headingId = useId();

  const bindNodeToIndex = (index: number) => (node: HTMLAnchorElement) => {
    listRef.current[index] = node;
  };

  return (
    <Box style={{ pointerEvents: 'auto' }}>
      <HeaderChevronButton
        ref={refs.setReference}
        {...getReferenceProps()}
        css={{
          transform: 'translate3d(0, 0, 0) !important',
          backgroundColor: open ? backgroundColor : 'transparent',
        }}
        isDark={isDark}
        id={headingId.toString()}
      >
        Trending
      </HeaderChevronButton>
      <div>
        {isMounted && (
          <FloatingFocusManager
            context={context}
            returnFocus={false}
            initialFocus={refs.floating}
          >
            <Menu.Root
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                ...styles,
              }}
              aria-labelledby={headingId}
              {...getFloatingProps()}
            >
              <Item
                itemRef={bindNodeToIndex(1)}
                href={getPath.trending.exhibitions}
                text="Exhibitions"
                icon={<ExhibitionIcon />}
                {...getItemProps()}
              />
              <Item
                itemRef={bindNodeToIndex(1)}
                href={getPath.trending.galleries}
                text="Galleries"
                icon={<GridIcon />}
                {...getItemProps()}
              />
              <Item
                itemRef={bindNodeToIndex(0)}
                href={getPath.trending.drops}
                text="Drops"
                icon={<DropCollectionIcon />}
                {...getItemProps()}
              />
              <Item
                itemRef={bindNodeToIndex(2)}
                href={getPath.trending.editions}
                text="Editions"
                icon={<EditionCollectionIcon />}
                {...getItemProps()}
              />
              <Item
                itemRef={bindNodeToIndex(3)}
                href="/browse/nfts"
                text="NFTs"
                icon={<NftIcon />}
                {...getItemProps()}
              />
            </Menu.Root>
          </FloatingFocusManager>
        )}
      </div>
    </Box>
  );
}

type ItemProps = {
  text: string;
  href: string;
  icon: React.ReactNode;
  meta?: React.ReactNode;
  itemRef?: (node: HTMLAnchorElement) => void;

  // These are provided by `getItemProps` from `useInteractions`
  onFocus?: () => void;
  onClick?: () => void;
  onMouseMove?: () => void;
  onPointerLeave?: () => void;
};

function Item(props: ItemProps) {
  const { href, icon, text, meta, itemRef, ...rest } = props;
  return (
    <NextLink href={href} passHref>
      <Menu.Item {...rest} as="a" ref={itemRef}>
        {icon}
        <Text size={1}>{text}</Text>
        {meta}
      </Menu.Item>
    </NextLink>
  );
}

export default ExploreDropdownMenu;
