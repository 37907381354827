import { any, find } from 'ramda';

import {
  useUserModeratedArtworks,
  UserModeratedArtworksVariables,
} from 'gql/hasura/queries/user-moderated-artworks.generated';
import { isFlaggedForModeration } from 'utils/moderation';

import { ModerationStatus } from 'types/Moderation';

type UserModerationState = {
  isUserOrArtworkModerated: boolean;
  isUserModerated: boolean;
  isArtworkModerated: boolean;
  moderationStatus: ModerationStatus;
};

export default function useUserModerationState(
  variables: Pick<UserModeratedArtworksVariables, 'publicKey'>
) {
  const { publicKey } = variables;

  return useUserModeratedArtworks<UserModerationState>(
    { publicKey, excludedModerationStatuses: ['ACTIVE'] },
    {
      enabled: Boolean(publicKey),
      select: (data): UserModerationState => {
        const user = data?.user;
        const userArtworks = user?.artworks ?? [];

        const moderatedArtwork = find(
          (artwork) => isFlaggedForModeration(artwork.moderationStatus),
          userArtworks
        );

        const hasModeratedArtwork = any(
          (artwork) => isFlaggedForModeration(artwork.moderationStatus),
          userArtworks
        );

        const isCreatorModerated = isFlaggedForModeration(
          user?.moderationStatus
        );

        const moderationStatus = isCreatorModerated
          ? user?.moderationStatus
          : moderatedArtwork?.moderationStatus;

        return {
          isUserOrArtworkModerated: isCreatorModerated || hasModeratedArtwork,
          isUserModerated: isCreatorModerated,
          isArtworkModerated: hasModeratedArtwork,
          moderationStatus: moderationStatus ?? 'ACTIVE',
        };
      },
    }
  );
}
