import {
  ChevronRightIcon,
  DropCollectionIcon,
  EditionCollectionIcon,
  HighlightIcon,
  NftIcon,
  OpenCollectionIcon,
  PlusIcon,
} from '@f8n/icons';
import { sizes } from '@f8n/tokens';
import { darkTheme, lightTheme, styled } from '@f8n-frontend/stitches';
import { AnimatePresence, motion } from 'framer-motion';
import Image, { StaticImageData } from 'next/image';
import NextLink from 'next/link';
import { ReactNode, useState } from 'react';

import Theme from 'components/Theme';
import Badge from 'components/base/Badge';
import Box from 'components/base/Box';
import Button, { ButtonProps } from 'components/base/Button';
import Heading from 'components/base/Heading';
import Link from 'components/base/Link';
import Modal from 'components/base/Modal';
import Tabs from 'components/base/Tabs';
import Text from 'components/base/Text';
import Tooltip from 'components/base/Tooltip';
import BackButton from 'components/buttons/BackButton';
import ConnectWalletButton from 'components/headers/ConnectWalletButton';
import TransactionWarningBlock from 'components/trust-safety/TransactionWarningBlock';
import useAnalytics from 'contexts/analytics/useAnalytics';
import { hasPublicKey } from 'contexts/auth/helpers';
import { AuthConnected } from 'contexts/auth/types';
import useAuth from 'contexts/auth/useAuth';

import { useCuratedStores } from 'gql/api/queries/curated-stores.generated';
import { useUserAvailableCollections } from 'gql/hasura/queries/user-available-collections.generated';
import useUserModerationState from 'hooks/queries/hasura/users/use-user-moderation-state';
import { useIsHydrated } from 'hooks/use-is-hydrated';
import { hasCuratedStores } from 'utils/curated-store';
import { getPath, helpCenterPaths } from 'utils/router';

import collectionImage from '../../public/create/collection.png';
import dropsImage from '../../public/create/drop.png';
import editionsImage from '../../public/create/edition.png';
import highlightImage from '../../public/create/highlight.jpg';
import mintImage from '../../public/create/mint.jpg';

const TAB = {
  COLLECTION: 'create-a-collection',
  DROP: 'launch-a-drop',
  EDITION: 'edition',
  MINT: 'mint-an-nft',
  HIGHLIGHT: 'import-from-highlight',
} as const;

type TabDescriptor = keyof typeof TAB;
type DefaultTabProps = (typeof TAB)[TabDescriptor];

type CreateModalProps = {
  buttonSize?: ButtonProps['size'];
  buttonVariant?: ButtonProps['variant'];
  defaultTab?: DefaultTabProps;
  isDark?: boolean;
};

type CreateModalSharedProps = {
  auth: AuthConnected;
  defaultTab?: DefaultTabProps;
};

type CreateModalBodyProps = CreateModalSharedProps & {
  hasCuratedStores: boolean;
};

function CreateModalBase(props: CreateModalProps) {
  const auth = useAuth();
  const { isDark, buttonSize, buttonVariant, defaultTab } = props;

  if (!hasPublicKey(auth)) {
    return (
      <ConnectWalletButton
        auth={auth}
        variant={buttonVariant}
        hasDarkBackground={isDark}
        size={buttonSize}
      />
    );
  }

  return (
    <CreateModalWrapped
      auth={auth}
      buttonVariant={buttonVariant}
      buttonSize={buttonSize}
      defaultTab={defaultTab}
      isDark={isDark}
    />
  );
}

function CreateModalWrapped(props: CreateModalProps & CreateModalSharedProps) {
  const { auth, buttonSize, isDark, defaultTab, buttonVariant } = props;

  const curatedStoresQuery = useCuratedStores(
    { creatorPublicKey: hasPublicKey(auth) ? auth.publicKey : '' },
    { enabled: hasPublicKey(auth) }
  );

  return (
    <ModalWrapper
      buttonSize={buttonSize}
      buttonVariant={buttonVariant}
      defaultTab={defaultTab}
      isDark={isDark}
    >
      <Modal.Content maxWidth={900} size={0} className={darkTheme}>
        <CreateModalBody
          auth={auth}
          defaultTab={defaultTab}
          hasCuratedStores={
            curatedStoresQuery.data
              ? hasCuratedStores(curatedStoresQuery.data.curatedStores)
              : false
          }
        />
      </Modal.Content>
    </ModalWrapper>
  );
}

function CreateModalBody(props: CreateModalBodyProps) {
  const { auth, defaultTab = TAB.MINT, hasCuratedStores } = props;
  const { publicKey } = auth;

  const [isTabOpen, setIsTabOpen] = useState(false);

  const { data: moderationData } = useUserModerationState({
    publicKey,
  });

  const isUserModerated = moderationData?.isUserModerated;
  const moderationStatus = moderationData?.moderationStatus;
  const hasModerationStatus =
    moderationStatus === 'SUSPENDED' || moderationStatus === 'UNDER_REVIEW';

  useUserAvailableCollections({ publicKey });

  const analytics = useAnalytics();

  if (isUserModerated && hasModerationStatus) {
    return (
      <Box
        className={lightTheme}
        css={{ paddingY: '$8', '@bp1': { paddingY: '$10' } }}
      >
        <TransactionWarningBlock moderationStatus={moderationStatus} />
      </Box>
    );
  }

  return (
    <Tabs.Root defaultValue={defaultTab} orientation="vertical">
      <ModalBody>
        <Sidebar
          className={lightTheme}
          aria-label="Create options"
          css={
            isTabOpen
              ? { '@bp1-max': { display: 'none' } }
              : { '@bp1-max': { display: 'block' } }
          }
        >
          <SidebarHeading>Create</SidebarHeading>
          <SidebarTab
            icon={NftIcon}
            onClick={() => setIsTabOpen(true)}
            tabName={TAB.MINT}
            title="NFT"
          />
          <SidebarTab
            icon={EditionCollectionIcon}
            onClick={() => setIsTabOpen(true)}
            tabName={TAB.EDITION}
            title="Edition"
          />
          <SidebarTab
            icon={DropCollectionIcon}
            onClick={() => setIsTabOpen(true)}
            tabName={TAB.DROP}
            title="Drop"
          />
          <SidebarTab
            icon={OpenCollectionIcon}
            onClick={() => setIsTabOpen(true)}
            tabName={TAB.COLLECTION}
            title="Collection"
          />
          <SidebarHeading>Integrations</SidebarHeading>
          <SidebarTab
            icon={HighlightIcon}
            onClick={() => setIsTabOpen(true)}
            tabName={TAB.HIGHLIGHT}
            title="Highlight"
            isNew
          />
        </Sidebar>
        <TabBody
          css={
            isTabOpen
              ? { '@bp1-max': { display: 'block' } }
              : { '@bp1-max': { display: 'none' } }
          }
        >
          {isTabOpen && (
            <PinTopLeft>
              <BackButton
                size={0}
                variant="blur"
                onClick={() => setIsTabOpen(false)}
              />
            </PinTopLeft>
          )}
          <Tab
            backgroundImage={mintImage}
            ctaText="Mint"
            description="Create a new NFT and mint it to one of your own ERC-721 smart contracts."
            icon={NftIcon}
            learnMoreUrl={helpCenterPaths.createACollection}
            onCtaClick={() => {
              analytics.track({
                name: 'clicked_create_modal_cta',
                type: 'nft',
              });
            }}
            pageUrl={getPath.create.mint}
            tabName={TAB.MINT}
            title="Mint an NFT"
          />
          <Tab
            backgroundImage={editionsImage}
            ctaText="Create"
            description="Multiple mints of the same artwork, limited either by time or supply."
            icon={EditionCollectionIcon}
            learnMoreUrl={helpCenterPaths.createAnEdition}
            onCtaClick={() => {
              analytics.track({
                name: 'clicked_create_modal_cta',
                type: 'edition',
              });
            }}
            pageUrl={getPath.create.edition}
            tabName={TAB.EDITION}
            title="Create an Edition"
          />
          <Tab
            backgroundImage={dropsImage}
            ctaText="Create"
            description="Make a large collection of NFTs that your community can mint."
            icon={DropCollectionIcon}
            learnMoreUrl={helpCenterPaths.createADrop}
            onCtaClick={() => {
              analytics.track({
                name: 'clicked_create_modal_cta',
                type: 'drop',
              });
            }}
            pageUrl={getPath.create.drop}
            tabName={TAB.DROP}
            title="Launch a Drop"
          />
          <Tab
            backgroundImage={collectionImage}
            ctaText="Create"
            description="Deploy an ERC-721 smart contract that you can mint to at any time."
            icon={OpenCollectionIcon}
            learnMoreUrl={helpCenterPaths.createACollection}
            onCtaClick={() => {
              analytics.track({
                name: 'clicked_create_modal_cta',
                type: 'collection',
              });
            }}
            pageUrl={getPath.create.collection}
            tabName={TAB.COLLECTION}
            title="Create a Collection"
          />
          <Tab
            hasCuratedStores={hasCuratedStores}
            badge={
              <Badge variant="blur" size={0}>
                Gallery exclusive
              </Badge>
            }
            ctaText="Import"
            description="Sell generative art with a gallery by importing a series you’ve created on Highlight."
            icon={HighlightIcon}
            pageUrl={getPath.importFrom.highlight}
            tabName={TAB.HIGHLIGHT}
            title="Import generative series"
            learnMoreUrl={helpCenterPaths.importingFromHighlight}
            onCtaClick={() => {
              analytics.track({
                name: 'clicked_create_modal_cta',
                type: 'import-from-highlight',
              });
            }}
            backgroundImage={highlightImage}
          />
        </TabBody>
      </ModalBody>
    </Tabs.Root>
  );
}

type ModalWrapperProps = {
  buttonSize?: ButtonProps['size'];
  buttonVariant?: ButtonProps['variant'];
  children: React.ReactNode;
  defaultTab?: DefaultTabProps;
  isDark?: boolean;
};

function ModalWrapper(props: ModalWrapperProps) {
  const {
    buttonSize = 0,
    buttonVariant = 'primary',
    children,
    defaultTab,
    isDark,
  } = props;
  const analytics = useAnalytics();
  return (
    <Modal.Root>
      <Theme theme={isDark ? 'dark' : 'light'}>
        <Modal.Trigger asChild>
          <Button
            onClick={() =>
              analytics.track({
                name: 'create_cta',
              })
            }
            size={buttonSize}
            variant={buttonVariant}
            icon={Boolean(getTriggerIcon(defaultTab))}
          >
            {getTriggerIcon(defaultTab)}
            <span>{getTriggerText(defaultTab)}</span>
          </Button>
        </Modal.Trigger>
      </Theme>
      {children}
    </Modal.Root>
  );
}

const getTriggerIcon = (defaultTab?: DefaultTabProps) => {
  switch (defaultTab) {
    case TAB.DROP:
      return <DropCollectionIcon />;
    case TAB.COLLECTION:
      return <OpenCollectionIcon />;
    case TAB.EDITION:
      return <EditionCollectionIcon />;
    case TAB.HIGHLIGHT:
      return <HighlightIcon />;
    default:
      return undefined;
  }
};

const getTriggerText = (defaultTab?: DefaultTabProps) => {
  switch (defaultTab) {
    default:
      return 'Create';
    case TAB.DROP:
      return 'Create a Drop';
    case TAB.COLLECTION:
      return 'Create a Collection';
    case TAB.EDITION:
      return 'Create an Edition';
  }
};

type IconProps = {
  size?: keyof typeof sizes.icons;
};

type Icon = React.ForwardRefExoticComponent<
  IconProps & React.RefAttributes<SVGSVGElement>
>;

type TabProps = {
  backgroundImage?: StaticImageData;
  badge?: ReactNode;
  hasCuratedStores?: boolean;
  ctaText: string;
  description: string;
  icon: Icon | null;
  learnMoreUrl?: string;
  onCtaClick: () => void;
  pageUrl: string;
  tabName: string;
  title: string;
};

function Tab(props: TabProps) {
  const {
    backgroundImage,
    badge,
    hasCuratedStores,
    ctaText,
    description,
    icon,
    learnMoreUrl,
    pageUrl,
    tabName,
    title,
    onCtaClick,
  } = props;

  const Icon = icon;

  const getTransition = (delay: number) => ({
    initial: { opacity: 0, y: 10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 10 },
    transition: { duration: 0.75, ease: [0.23, 1, 0.32, 1], delay },
  });

  const getButton = () => {
    if (tabName === 'import-from-highlight' && !hasCuratedStores) {
      return (
        <Tooltip
          content="Create or join a gallery to import a generative series."
          size={0}
          align="left"
          width="small"
        >
          <CtaButton disabled>
            <span>import</span>
            <PlusIcon />
          </CtaButton>
        </Tooltip>
      );
    }

    return (
      <CtaLinkButton href={pageUrl} onClick={onCtaClick}>
        {ctaText}
      </CtaLinkButton>
    );
  };

  return (
    <Tabs.Content
      css={{
        height: '556px !important',
      }}
      value={tabName}
    >
      <TabContentInnner>
        {backgroundImage && <TabImage src={backgroundImage} />}
        <Gradient />
        <Footer>
          <Box>
            <AnimatePresence>
              {Icon && (
                <IconWrapper {...getTransition(0.1)}>
                  <Icon size={4} />
                  {badge}
                </IconWrapper>
              )}
            </AnimatePresence>
            <AnimatePresence>
              <motion.div {...getTransition(0.2)}>
                <Heading
                  lineHeight={0}
                  size={{ '@initial': 5, '@bp1': 6 }}
                  css={{ paddingY: '$2' }}
                >
                  {title}
                </Heading>
              </motion.div>
            </AnimatePresence>
            <AnimatePresence>
              <motion.div {...getTransition(0.3)}>
                <Text lineHeight={2} size={2} css={{ maxWidth: '330px' }}>
                  {description}{' '}
                  {learnMoreUrl && (
                    <Link
                      css={{ '@bp1-max': { display: 'none' } }}
                      hasUnderline
                      href={learnMoreUrl}
                      target="_blank"
                      variant="strong"
                    >
                      Learn more
                    </Link>
                  )}
                </Text>
              </motion.div>
            </AnimatePresence>
          </Box>
          <AnimatePresence>
            <motion.div {...getTransition(0.4)}>{getButton()}</motion.div>
          </AnimatePresence>
          <AnimatePresence>
            <LearnMoreButtonWrapper {...getTransition(0.5)}>
              <Button as="a" href={learnMoreUrl} size={0} variant="ghost">
                Learn more
              </Button>
            </LearnMoreButtonWrapper>
          </AnimatePresence>
        </Footer>
      </TabContentInnner>
    </Tabs.Content>
  );
}

type TabImageProps = {
  src: StaticImageData;
};

function TabImage(props: TabImageProps) {
  const { src } = props;

  const isHydrated = useIsHydrated();

  const transition = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.6, ease: [0.23, 1, 0.32, 1] },
  };

  return (
    <AnimatePresence>
      {isHydrated && (
        <motion.div {...transition}>
          <Image
            src={src}
            alt=""
            layout="fill"
            objectFit="cover"
            placeholder="blur"
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

const CtaButton = styled(Button, {
  whiteSpace: 'nowrap',
});

CtaButton.defaultProps = {
  size: {
    '@initial': 2,
    '@bp1': 1,
  },
  icon: true,
  variant: 'primary',
};

type CtaLinkButtonProps = {
  children: React.ReactNode;
  href: string;
  onClick: () => void;
};

function CtaLinkButton(props: CtaLinkButtonProps) {
  const { children, href, onClick } = props;
  return (
    <NextLink href={href} passHref prefetch={false}>
      <CtaButton as="a" onClick={onClick}>
        <span>{children}</span>
        <PlusIcon />
      </CtaButton>
    </NextLink>
  );
}

type SidebarTabProps = {
  icon: Icon;
  onClick: () => void;
  tabName: string;
  title: string;
  isNew?: boolean;
};

function SidebarTab(props: SidebarTabProps) {
  const { icon, isNew, title, tabName, onClick } = props;
  const Icon = icon;
  return (
    <SidebarTrigger onClick={onClick} value={tabName}>
      <Icon />
      {title}
      {isNew && (
        <Badge size={0} variant="new">
          NEW
        </Badge>
      )}
      <ChevronWrapper>
        <ChevronRightIcon size={1} />
      </ChevronWrapper>
    </SidebarTrigger>
  );
}

const ModalBody = styled('div', {
  display: 'flex',
  alignItems: 'stretch',
});

const TabBody = styled('div', {
  flex: '1',
  color: '$black100',
  background: '$white100',
  '@bp1-max': {
    display: 'none',
    overflow: 'hidden',
    borderTopLeftRadius: '$4',
    borderTopRightRadius: '$4',
  },
});

const TabContentInnner = styled('div', {
  height: '100%',
  display: 'flex',
  color: '$black100',
  position: 'relative',
  alignItems: 'flex-end',
  background: '$white100',
  paddingX: '$7',
  paddingTop: '$8',
  paddingBottom: 'calc($8 + env(safe-area-inset-bottom))',
});

const Sidebar = styled(Tabs.List, {
  gap: '$2',
  flex: '1',
  flexDirection: 'column',
  backgroundColor: '$white100',

  paddingX: '$4',
  paddingTop: '$6',
  paddingBottom: 'calc($6 + env(safe-area-inset-bottom))',

  '@bp1': {
    padding: '$5',
    maxWidth: '220px',
  },
});

const SidebarTrigger = styled(Tabs.Trigger, {
  width: '100%',
  height: 'auto',
  paddingY: '$3',
  lineHeight: '$0',
  borderRadius: '$2',

  gap: '$4',
  fontSize: '$3',

  '@bp1': {
    gap: '$2',
    fontSize: '$2',
  },

  '@bp1-max': {
    paddingX: '$3',
    lineHeight: '$2',
    '> svg:first-child': {
      width: '$icon2',
      height: '$icon2',
    },
    '&[data-state="active"]': {
      background: 'transparent',
    },
  },
});

const SidebarHeading = styled(Heading, {
  letterSpacing: '0.07em !important',
  marginBottom: '$1',
  marginLeft: '$3',
  marginTop: '$6',

  '&:first-of-type': {
    marginTop: '$4',
  },
});

SidebarHeading.defaultProps = {
  size: 0,
  color: 'dim',
  uppercase: true,
};

const IconWrapper = styled(motion.div, {
  gap: '$3',
  marginBottom: '$2',
  display: 'flex',
  alignItems: 'center',
  svg: { display: 'block' },
});

const Footer = styled('div', {
  gap: '$6',
  width: '100%',
  display: 'flex',
  position: 'relative',

  '@bp1': {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },

  '@bp1-max': {
    flexDirection: 'column',
    justifyContent: 'stretch',
    [`${Button}`]: { width: '100%' },
  },
});

const Gradient = styled('div', {
  top: 0,
  right: 0,
  width: '100%',
  height: '100%',
  position: 'absolute',
  background: '$overlay',

  '@bp1': {
    background:
      'linear-gradient(180deg, rgba(0, 0, 0, 0) 23.96%, rgba(0, 0, 0, 0.85) 79.69%)',
  },
});

const PinTopLeft = styled('div', {
  top: '$4',
  left: '$4',
  zIndex: 1,
  position: 'absolute',
  '@bp1': { display: 'none' },
});

const ChevronWrapper = styled('div', {
  marginLeft: 'auto',
  '@bp1': { display: 'none' },
});

const LearnMoreButtonWrapper = styled(motion.div, {
  '@bp1': {
    display: 'none',
  },
});

const CreateModal = Object.assign(CreateModalBase, {
  Body: CreateModalBody,
  TAB,
});

export default CreateModal;
