import { UseQueryOptions } from '@tanstack/react-query';

import {
  useUserActivityCount as useUserActivityCountBaseHook,
  UserActivityCount,
  UserActivityCountVariables,
} from 'gql/hasura/queries/user-activity-count.generated';
import { getAggregateCount } from 'utils/aggregate';
import { isAllTrue } from 'utils/helpers';

const currentDate = new Date().toISOString();

export default function useUserActivityCount(
  variables: Pick<UserActivityCountVariables, 'publicKey'>,
  options?: UseQueryOptions<UserActivityCount, Error, number>
) {
  return useUserActivityCountBaseHook(
    { ...variables, currentDate },
    {
      ...options,
      enabled: isAllTrue(Object.values(variables)),
      select: (data) => {
        const placedBidsOpenCount = getAggregateCount(data.placedBidsOpenCount);
        // prettier-ignore
        const placedBidsEndedCount = getAggregateCount(data.placedBidsEndedCount);
        const receivedBidsCount = getAggregateCount(data.receivedBidsCount);
        const privateSalesCount = getAggregateCount(data.privateSalesCount);
        const receivedOffersCount = getAggregateCount(data.receivedOffersCount);
        const madeOffersCount = getAggregateCount(data.madeOffersCount);
        return (
          placedBidsOpenCount +
          placedBidsEndedCount +
          receivedBidsCount +
          privateSalesCount +
          receivedOffersCount +
          madeOffersCount
        );
      },
    }
  );
}
