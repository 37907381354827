import NextLink from 'next/link';

import WorldTag from 'components/worlds/WorldTag';

import { getPath } from 'utils/router';

import { WorldSearchResult } from 'types/World';

import SearchResultElement from './search-result/SearchResultElement';

interface SearchHitWorldProps {
  world: WorldSearchResult;
  onClick: (world: WorldSearchResult) => void;
}

export default function SearchHitWorld(props: SearchHitWorldProps) {
  return (
    <NextLink
      href={getPath.world.page(props.world.slug)}
      prefetch={false}
      passHref
    >
      <SearchResultElement as="a" onClick={() => props.onClick(props.world)}>
        <WorldTag type="list-item" world={props.world} />
      </SearchResultElement>
    </NextLink>
  );
}
