import NextLink from 'next/link';

import DetailsTag from 'components/DetailsTag';

import { ApiMomentsSearch } from 'gql/api/queries/moments-search.generated';
import { getPath } from 'utils/router';

import SearchResultElement from './search-result/SearchResultElement';

type MomentSearchResult = ApiMomentsSearch['momentsSearch']['items'][0];

interface SearchHitMomentProps {
  moment: MomentSearchResult;
  onClick: (moment: MomentSearchResult) => void;
}

export default function SearchHitMoment(props: SearchHitMomentProps) {
  return (
    <NextLink
      href={getPath.moment.page({
        worldSlug: props.moment.worldSlug,
        momentId: props.moment.id,
      })}
      passHref
    >
      <SearchResultElement
        css={{ cursor: 'pointer' }}
        as="a"
        onClick={() => props.onClick(props.moment)}
      >
        <DetailsTag.Moment moment={props.moment} />
      </SearchResultElement>
    </NextLink>
  );
}
