import { CID } from 'multiformats/cid';
import qs from 'qs';
import {
  T,
  allPass,
  compose,
  cond,
  curry,
  ifElse,
  includes,
  last,
  omit,
  pick,
  prop,
  propEq,
  propSatisfies,
  when,
} from 'ramda';
import { P, match } from 'ts-pattern';
import { z } from 'zod';

import type { PrerevealAsset } from 'hooks/queries/hasura/collections/use-prereveal-asset';
import {
  VIDEO_ASSET_QUALITY_VARIANTS,
  imageAssetsHost,
  imgixProxyOrigin,
  modelImageAssetsHost,
  videoAssetsHost,
} from 'lib/assets';
import { IPFS_GATEWAY_URL_STRING } from 'lib/constants';
import report from 'lib/report';
import { isString, notEmptyOrNil } from 'utils/helpers';
import {
  getFileExtensionFromFileName,
  getFileName,
  isValidURL,
  replaceUrlPath,
} from 'utils/urls';

import {
  ArtworkAssetFields,
  ArtworkV2,
  AssetStatus,
  BasicArtwork,
} from 'types/Artwork';
import {
  AssetMimeType,
  ImgixOptions,
  MediaType,
  VideoAssetOptions,
} from 'types/Assets';
import { FallbackAssets } from 'types/artwork/artwork';
import { MediaAsset } from 'types/media';
import { Maybe } from 'types/utils';

import { mapImgixGifToMp4 } from './imgix';
import { UPLOAD_MIME_TYPES } from './mimeTypes';

/**
 * @deprecated
 * - move to API or transform layer
 */
export type _Unsafe_ArtworkAsset = ArtworkV2 & {
  assetHost: string;
  assetId: string;
  assetPath: string;
  assetScheme: string;
};

type FormatExtension = {
  extension: string;
  mimetype: AssetMimeType;
};

const MODEL_FORMAT_EXTENSIONS: FormatExtension[] = [
  { extension: 'glb', mimetype: 'model/gltf-binary' },
  { extension: 'gltf', mimetype: 'model/gltf+json' },
];

export const getIpfsHash = (assetIpfsPath: Maybe<string>) => {
  if (assetIpfsPath) {
    return assetIpfsPath.split('/').find((s: string) => {
      try {
        return Boolean(CID.parse(s));
      } catch (err) {
        return false;
      }
    });
  } else {
    return undefined;
  }
};

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function bytesToSize(bytes: number): string {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) {
    return '0 Byte';
  }
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const value = sizes[i] as string;
  return Math.round(bytes / Math.pow(1024, i)) + value;
}

const isVideo = (fileUrl: string): boolean =>
  /\.(mp4)$/i.test(getFileName(fileUrl) || '');

export const isModel = (fileUrl: string): boolean =>
  /\.(gltf|glb)$/i.test(getFileName(fileUrl) || '');

export const createModelMimeType = (fileName: string): string | null => {
  const fileExtension = fileName.split('.').pop();
  const modelMimetype = MODEL_FORMAT_EXTENSIONS.find(
    (o) => o.extension === fileExtension
  );
  return modelMimetype ? modelMimetype.mimetype : null;
};

const IMGIX_OPTION_KEYS: (keyof ImgixOptions)[] = [
  'q',
  'w',
  'h',
  'auto',
  'fit',
  'max-w',
  'max-h',
  'min-h',
  'min-w',
  'fm',
  'dpr',
  'bg',
  'exp',
  'blur',
];

export const COLLECTION_IMAGE_IMGIX_BLUR = 180;
export const FALLBACK_IMAGE_IMGIX_BLUR = 500;

/** @deprecated prefer optimizeAsset where possible */
export function buildUrlWithImgixParams(
  url: string,
  options: ImgixOptions = {}
) {
  // pick out only the options relevant to imgix
  const imageOptions = pick<ImgixOptions, string>(IMGIX_OPTION_KEYS, {
    // default options
    q: 50,
    // override options
    ...options,
  });

  const urlObject = new URL(url);
  urlObject.search = qs.stringify(imageOptions);
  return urlObject.toString();
}

/** @deprecated prefer optimizeAsset where possible */
export function buildImgixUrl(
  url: string | null,
  options: ImgixOptions = {},
  origin = imgixProxyOrigin
): string | null {
  const hasUrl = notEmptyOrNil(url);

  if (!hasUrl || !url) {
    return null;
  }

  // pick out only the options relevant to imgix
  const imageOptions = pick<ImgixOptions, string>(IMGIX_OPTION_KEYS, {
    // default options
    q: 50,
    // override options
    ...options,
  });

  const queryString = qs.stringify(imageOptions);

  try {
    const urlObject = new URL(url);

    return `${origin}${urlObject.pathname}?${queryString}`;
  } catch (error) {
    return `${origin}/${url}?${queryString}`;
  }
}

interface BuildImgixAssetUrlArgs {
  assetScheme: string;
  assetHost: string;
  assetPath: string;
  options: ImgixOptions;
}

/** @deprecated prefer optimizeAsset where possible */
function buildImgixAssetUrl({
  assetScheme,
  assetHost,
  assetPath,
  options = {},
}: BuildImgixAssetUrlArgs): string {
  const imageOptions = pick<ImgixOptions, string>(IMGIX_OPTION_KEYS, options);

  const imgixOpts: ImgixOptions = {
    q: 50,
    auto: 'compress',
    cs: 'srgb',
    ...imageOptions,
  };

  // Avoid optimizing SVGs via imgix
  const queryString =
    assetPath && assetPath.endsWith('.svg') ? '' : qs.stringify(imgixOpts);

  try {
    const hasURLSchemeHost = Boolean(assetScheme && assetHost);
    const urlObject = new URL(
      hasURLSchemeHost ? `${assetScheme}${assetHost}` : imageAssetsHost
    );

    // Note: The ipfs part of the path isn't needed since that's contained
    // in the Imgix web folder config
    urlObject.pathname = assetPath;

    urlObject.search = queryString;
    const urlString = urlObject.toString();
    return urlString;
  } catch (error) {
    report(error);
    throw error;
  }
}

/** @deprecated prefer optimizeAsset where possible */
export const buildImgixUrlNew = curry(
  (options: ImgixOptions, url: string): string | null => {
    return buildImgixUrl(url, options);
  }
);

export const whenURLIsValid = curry(
  (fn: (arg0: string) => string, url: string | undefined | null) => {
    if (!url) return null;

    if (isValidURL(url)) {
      return fn(url);
    }

    return null;
  }
);

export const mimeTypeExtensions = {
  'image/jpeg': 'jpg',
  'image/jpg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/svg+xml': 'svg',
  'video/mp4': 'mp4',
  'video/quicktime': 'mov',
};

interface FileDimensions {
  width: number;
  height: number;
  duration?: number;
}

const getDimensionsFromImage = (file: File): Promise<FileDimensions> =>
  new Promise((resolve) => {
    const fileAsDataURL = window.URL.createObjectURL(file);
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.src = fileAsDataURL;
  });

const getDimensionsFromVideo = (file: File): Promise<FileDimensions> =>
  new Promise((resolve) => {
    const url = URL.createObjectURL(file);
    const video = document.createElement('video');
    video.src = url;
    video.onloadedmetadata = () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
        duration: video.duration,
      });
    };
  });

// if the file’s type contains `video/` return true
const hasVideoMimeType = compose(includes('video/'), prop('type'));
const hasImageMimeType = compose(includes('image/'), prop('type'));

// if it’s a video mimeType, use the video file handler
// otherwise use the image one
const handleFileByType = cond([
  [hasVideoMimeType, getDimensionsFromVideo],
  [hasImageMimeType, getDimensionsFromImage],
  [T, () => ({ width: 0, height: 0 })],
]);

export const getDimensionsFromFile = (file: File): Promise<FileDimensions> => {
  return handleFileByType(file);
};

export const buildFallbackCoverImageUrl = compose<string | null, string>(
  whenURLIsValid(
    buildImgixUrlNew({
      q: 75,
      w: 1600,
      auto: 'format,compress',
      fit: 'fill',
      exp: -10,
      blur: FALLBACK_IMAGE_IMGIX_BLUR,
    })
  )
);

export const buildArtworkFooterImageUrl = compose<string | null, string>(
  whenURLIsValid(
    buildImgixUrlNew({
      blur: COLLECTION_IMAGE_IMGIX_BLUR,
      'max-w': 1200,
      'max-h': 596,
      auto: 'format,compress',
      fit: 'crop',
      exp: -5,
    })
  )
);

// artwork has a video mimeType
const isVideoMimeType = propSatisfies(
  // guard the check only when it’s a string
  when(isString, includes('video/')),
  'mimeType'
);
const isImageMimeType = propSatisfies(
  // guard the check only when it’s a string
  when(isString, includes('image/')),
  'mimeType'
);

export const isModelMimeType = propSatisfies(
  // guard the check only when it’s a string
  when(isString, includes('model/')),
  'mimeType'
);

const isGifMimeType = propSatisfies(
  // guard the check only when it’s a string
  when(isString, includes('image/gif')),
  'mimeType'
);

// TODO: Revisit this logic. assetStatus is changing to make PENDING a transient state.
const hasVideoAssetSuccessStatus = propEq('assetStatus', 'SUCCESS');

export const buildS3BaseAssetUrl = ({
  assetId,
  assetPath,
  assetVersion,
}: {
  assetId: string;
  assetPath?: string;
  assetVersion?: number;
}): string => {
  if (assetPath && (!assetId || (assetVersion && assetVersion >= 5))) {
    return assetPath;
  }

  return buildS3FolderStructure(assetId);
};

type LegacyEditionArtwork = {
  mimeType: string;
  assetScheme: string;
  assetHost: string;
  assetPath: string;
};

export const buildLegacyEditionPosterAsset = (
  artwork: LegacyEditionArtwork
): string => {
  if (
    artwork.assetPath.startsWith('/') &&
    artwork.assetHost === 'assets.foundation.app'
  ) {
    const assetPath = [
      artwork.assetPath,
      VIDEO_ASSET_QUALITY_VARIANTS.POSTER,
    ].join('/');

    return new URL(
      assetPath,
      artwork.assetScheme + artwork.assetHost
    ).toString();
  } else {
    const [assetCid] = artwork.assetPath.split('/');
    if (!assetCid) {
      return '';
    }

    const lastFourChars = assetCid.slice(-4);
    const pathPartOne = lastFourChars.substring(0, 2);
    const pathPartTwo = lastFourChars.substring(2);
    const pathPartThree = assetCid;

    /**
     * as Editions use the legacy asset creation code-path on the node server
     * it means we need to support this legacy structure
     */
    const assetPath = [
      pathPartOne,
      pathPartTwo,
      pathPartThree,
      VIDEO_ASSET_QUALITY_VARIANTS.POSTER,
    ].join('/');

    return new URL(
      assetPath,
      artwork.assetScheme + artwork.assetHost
    ).toString();
  }
};

const buildS3FolderStructure = (assetId: string) => {
  // For perf reasons we generate extra folders as it allows for more throughput
  // folder1 is 2 letters from the 4th from the end
  // folder2 is 2 letters from the 2nd from the end
  const folder1 = assetId?.substr(-4, 2);
  const folder2 = assetId?.substr(-2, 2);

  // Use the last 4 chars of the assetIPFSId to ensure even distribution of keys on first characters
  return `/${folder1}/${folder2}/${assetId}`;
};

const getVideoAssetHost = (artwork: ArtworkV2) => {
  const hasURLSchemeHost = Boolean(artwork.assetScheme && artwork.assetHost);
  const urlHost = hasURLSchemeHost
    ? `${artwork.assetScheme}${artwork.assetHost}`
    : videoAssetsHost;

  return urlHost;
};

const getPreviewVideoFilename = (options: VideoAssetOptions) => {
  const { isOversized } = options;
  return isOversized ? '/nft_preview.mp4' : '/nft_preview_q3.mp4';
};

/**
 * @deprecated
 * - move to API
 * - too risky to change
 * */
const buildPreviewVideoUrl = cond([
  [
    propEq('assetVersion', 6),
    (artwork: _Unsafe_ArtworkAsset, options: VideoAssetOptions) => {
      return (
        artwork.assetScheme +
        artwork.assetHost +
        artwork.assetPath +
        getPreviewVideoFilename(options)
      );
    },
  ],
  [
    propEq('assetVersion', 5),
    (artwork: _Unsafe_ArtworkAsset, options: VideoAssetOptions) => {
      return (
        artwork.assetScheme +
        artwork.assetHost +
        artwork.assetPath +
        getPreviewVideoFilename(options)
      );
    },
  ],
  [
    propEq('assetVersion', 3),
    (artwork: _Unsafe_ArtworkAsset, options: VideoAssetOptions) => {
      return `${getVideoAssetHost(artwork)}${buildS3BaseAssetUrl({
        assetId: artwork.assetId,
        assetPath: artwork.assetPath,
        assetVersion: artwork.assetVersion,
      })}${getPreviewVideoFilename(options)}`;
    },
  ],
  [
    T,
    (artwork: _Unsafe_ArtworkAsset) => {
      return `${getVideoAssetHost(artwork)}${buildS3BaseAssetUrl({
        assetId: artwork.assetId,
        assetPath: artwork.assetPath,
        assetVersion: artwork.assetVersion,
      })}/nft_preview.mp4`;
    },
  ],
]);

/**
 * @deprecated
 * - move to API
 * - too risky to change
 * */
const buildVideoUrl = cond([
  [
    propEq('assetVersion', 6),
    (artwork: _Unsafe_ArtworkAsset) =>
      artwork.assetScheme + artwork.assetHost + artwork.assetPath + '/nft.mp4',
  ],
  [
    propEq('assetVersion', 5),
    (artwork: _Unsafe_ArtworkAsset) =>
      artwork.assetScheme + artwork.assetHost + artwork.assetPath + '/nft.mp4',
  ],
  [
    propEq('assetVersion', 3),
    (artwork: _Unsafe_ArtworkAsset) =>
      `${getVideoAssetHost(artwork)}${buildS3BaseAssetUrl({
        assetId: artwork.assetId,
        assetPath: artwork.assetPath,
        assetVersion: artwork.assetVersion,
      })}/nft_q4.mp4`,
  ],
  [
    T,
    (artwork: _Unsafe_ArtworkAsset) =>
      `${getVideoAssetHost(artwork)}${buildS3BaseAssetUrl({
        assetId: artwork.assetId,
        assetPath: artwork.assetPath,
        assetVersion: artwork.assetVersion,
      })}/nft.mp4`,
  ],
]);

export const buildPosterImageUrl = (
  artwork: _Unsafe_ArtworkAsset
): string | undefined => {
  return `${getVideoAssetHost(artwork)}${buildS3BaseAssetUrl({
    assetId: artwork.assetId,
    assetPath: artwork.assetPath,
    assetVersion: artwork.assetVersion,
  })}/nft.jpg`;
};

/**
 * @deprecated
 * - move to API
 * - too risky to change
 * */
const buildModelPosterImageUrl = (
  artwork: ArtworkAssetFields,
  options?: ImgixOptions
) => {
  // in the case of asset v5 we build the structure based off assetId
  if (
    artwork.assetVersion !== 5 &&
    artwork.assetVersion !== 6 &&
    artwork.assetId
  ) {
    const path = buildS3FolderStructure(artwork.assetId);
    const url = `${modelImageAssetsHost}${path}/nft.png`;

    return buildImgixUrl(url, options, modelImageAssetsHost);
  }

  // otherwise we check for presence of assetId and fall-back to assetpath
  const assetCid = artwork.assetId || getIpfsHash(artwork.assetPath);
  const path = buildS3FolderStructure(assetCid as string);
  const url = `${modelImageAssetsHost}${path}/nft.png`;
  return buildImgixUrl(url, options, modelImageAssetsHost);
};

export const buildArtworkModelUrl = (artwork: ArtworkV2): string => {
  return cond<ArtworkV2, string>([
    [
      propEq('assetVersion', 6),
      (artwork: _Unsafe_ArtworkAsset) =>
        artwork.assetScheme + artwork.assetHost + artwork.assetPath,
    ],
    [
      propEq('assetVersion', 5),
      (artwork: _Unsafe_ArtworkAsset) =>
        artwork.assetScheme + artwork.assetHost + artwork.assetPath,
    ],
    [
      T,
      (artwork: _Unsafe_ArtworkAsset) =>
        `${artwork.assetScheme}${artwork.assetHost}/ipfs/${artwork.assetPath}`,
    ],
  ])(artwork);
};

export const buildPosterUrl = cond([
  [
    (artwork) => isModel(artwork.assetPath),
    (artwork, options) => buildModelPosterImageUrl(artwork, options),
  ],
  [allPass([isVideoMimeType, hasVideoAssetSuccessStatus]), buildPosterImageUrl],
  [T, () => null],
]);

const buildArtworkVideoUrl = curry(
  (options: ImgixOptions, artwork: ArtworkV2) =>
    cond([
      [
        propEq('quality', 'preview'),
        () => buildPreviewVideoUrl(artwork, options),
      ],
      [T, () => buildVideoUrl(artwork)],
    ])(options)
);

/**
 * @deprecated
 * - move to API
 * - too risky to change
 * */
const buildArtworkImageUrl = curry(
  (options: ImgixOptions, artwork: _Unsafe_ArtworkAsset) => {
    return buildImgixAssetUrl({
      assetScheme: artwork.assetScheme,
      assetHost: artwork.assetHost,
      assetPath: artwork.assetPath,
      options,
    });
  }
);

/**
 * @deprecated
 * - move to API
 * - too risky to change
 * */
const buildArtworkGifImageUrl = curry(
  (options: ImgixOptions, artwork: _Unsafe_ArtworkAsset) => {
    // We filter out the auto prop as the auto=format, destroys gif performance
    // Exmaples can be seen in any browser apart from chrome
    const newOptions = omit(['auto'], options);
    return buildImgixAssetUrl({
      assetScheme: artwork.assetScheme,
      assetHost: artwork.assetHost,
      assetPath: artwork.assetPath,
      options: newOptions,
    });
  }
);

/**
 * @deprecated
 * - move to API
 * - too risky to change
 * */
export const buildArtworkAssetUrl = curry(
  (
    options: ImgixOptions,
    artwork: Omit<ArtworkAssetFields, 'assetIPFSPath'>
  ): string =>
    cond([
      [isGifMimeType, buildArtworkGifImageUrl(options)],
      [isVideoMimeType, buildArtworkVideoUrl(options)],
      [isImageMimeType, buildArtworkImageUrl(options)],
      [isModelMimeType, buildArtworkModelUrl],
    ])(artwork)
);

/**
 * @deprecated
 * - move to API
 * - too risky to change
 * */
export const buildAssetStaticImage = curry(
  (options: ImgixOptions, artwork: ArtworkAssetFields) =>
    ifElse(
      isVideoMimeType,
      buildPosterUrl,
      buildArtworkImageUrl(options)
    )(artwork)
);

export const buildActivityCardFallbackAssetUrl = compose<string | null, string>(
  whenURLIsValid(
    buildImgixUrlNew({
      h: 260,
      quality: 'preview',
      auto: 'format,compress',
      exp: -5,
      blur: FALLBACK_IMAGE_IMGIX_BLUR,
    })
  )
);

const cardAssetDefaults: ImgixOptions = {
  'max-w': 960,
  'max-h': 960,
  quality: 'preview',
  auto: 'format,compress',
};

export const buildNoAssetArtworkCardUrl = compose<string | null, string>(
  whenURLIsValid(
    buildImgixUrlNew({
      ...cardAssetDefaults,
      // slightly lighter than buildArtworkFooterImageUrl to create some contrast
      // when the ArtworkCard is shown within the ArtworkFooter
      exp: -2,
      blur: FALLBACK_IMAGE_IMGIX_BLUR,
    })
  )
);

export const buildArtworkCardAssetUrl = buildArtworkAssetUrl({
  ...cardAssetDefaults,
});

export const buildArtworkPageAssetUrl = buildArtworkAssetUrl({
  q: 80,
  w: 3000,
  h: 3000,
  quality: 'max',
  auto: 'format,compress',
  fit: 'max',
});

export const buildIPFSAssetUrl = (artwork: BasicArtwork) => {
  return `https://ipfs.io/ipfs/${artwork.assetIPFSPath}`;
};

export const replaceIpfsPath = (ipfsPath: string) => {
  return ipfsPath.replace('ipfs://', IPFS_GATEWAY_URL_STRING);
};

export const removeIpfsPath = (ipfsPath: string) => {
  return ipfsPath.replace('ipfs://', '');
};

export type FileSizesArray = Array<AssetSize[]>;
type AssetSize = {
  asset: { size: number };
};

export function splitAssetsByAccumSize<Asset extends AssetSize>(
  assets: Asset[],
  chunkSizeInBytes: number
): Asset[][] {
  return assets.reduce((acc, curr) => {
    // Array.at(-1) is not supported in our node enviroment + unit tests
    const arrTail: Asset[] | undefined = last(acc);

    // handle the first iteration / base case
    if (!Array.isArray(arrTail)) {
      // ensure we are returning an array of arrays
      return [[curr]];
    }

    // get the total size of the files in the tail array
    const accumFileSize = arrTail.reduce(
      (acc, curr) => acc + curr.asset.size,
      0
    );
    // get all but the last item from the array
    const arrInit = acc.slice(0, -1);

    // if the accum. file size + the current file size <= the threshold
    if (accumFileSize + curr.asset.size <= chunkSizeInBytes) {
      // return the current file appended to the tail
      return [...arrInit, [...arrTail, curr]];
    } else {
      // otherwise append a new tail array with the current file
      return [...arrInit, arrTail, [curr]];
    }
  }, [] as Asset[][]);
}

type RenameFileArgs = { file: File; baseName: string };

export const renameFile = (args: RenameFileArgs): File => {
  const fileTypeExtension = getFileExtensionFromFileName(args.file.name);

  const newFileName = fileTypeExtension
    ? `${args.baseName}.${fileTypeExtension}`
    : args.baseName;

  return new File([args.file], newFileName, {
    type: args.file.type,
  });
};

type FallbackArtwork = {
  assetStatus: AssetStatus;
  id: string;
};

export function mapFailedArtworksToIds(
  artworks: Array<FallbackArtwork | undefined | null>
): string[] {
  if (Array.isArray(artworks)) {
    // map the artworks
    return artworks
      .filter(
        (artwork): artwork is FallbackArtwork =>
          artwork !== undefined &&
          artwork !== null &&
          artwork?.assetStatus === 'FAILED'
      )
      .map((artwork) => artwork?.id);
  } else {
    return [];
  }
}

export function findFallbackAsset(assets: FallbackAssets, artworkId: string) {
  return Array.isArray(assets)
    ? assets.find((asset) => asset.artwork?.id === artworkId)
    : undefined;
}

interface FindAssetUrlArgs {
  isUnsupportedAsset: boolean;
  assetStatus: string;
  publicUrl: string | undefined;
  optimizedAssetUrl: string;
}

// checks asset status, if it's unsupported, if there's an optimized asset URL, before returning the fallback asset
export function findAssetUrl(props: FindAssetUrlArgs) {
  const { isUnsupportedAsset, assetStatus, optimizedAssetUrl, publicUrl } =
    props;
  const hasAssetUrl = Boolean(optimizedAssetUrl);
  return hasAssetUrl && !isUnsupportedAsset && assetStatus !== 'FAILED'
    ? optimizedAssetUrl
    : publicUrl ?? null;
}

export function getFallbackAssetMediaType(
  mimeType: Maybe<string> | undefined
): MediaType | undefined {
  if (mimeType) {
    // find from the list of mimetypes we support on foundation
    const matchedMimeType = UPLOAD_MIME_TYPES.find(
      (format) => format.toLowerCase() === mimeType.toLowerCase()
    );
    // given 'image/jpg' will return 'image'
    return matchedMimeType
      ? (matchedMimeType.split('/')[0] as MediaType)
      : undefined;
  } else {
    return undefined;
  }
}

export function getOptimizedAssetMediaType(
  url: Maybe<string> | undefined
): MediaType | undefined {
  if (url) {
    return isVideo(url) ? 'video' : isModel(url) ? 'model' : 'image';
  } else {
    return undefined;
  }
}

export const buildImageUrlFromAnyAsset = (artwork: ArtworkV2) =>
  match(artwork)
    .with(
      {
        mimeType: P.union('video/mp4', 'video/quicktime'),
        assetHost: P.string,
        assetScheme: P.string,
        assetId: P.string,
        assetPath: P.string,
      },
      buildPosterImageUrl
    )
    .otherwise((artwork) =>
      buildArtworkAssetUrl(
        { w: 144, fm: 'jpg', auto: 'format,compress' },
        artwork
      )
    );

export const mapDropAssetToMediaProps = (
  prerevealAsset: PrerevealAsset,
  collectionName: string
) => {
  return match<PrerevealAsset, MediaAsset | null>(prerevealAsset)
    .with({ mimeType: 'image/gif', assetUrl: P.select() }, (assetUrl) => {
      return mapImgixGifToMp4(assetUrl, {
        q: 50,
        'max-w': 640,
      });
    })
    .with({ assetUrl: P.select() }, (assetUrl) => {
      return {
        type: 'image',
        src: assetUrl,
        alt: collectionName,
      };
    })
    .otherwise(() => null);
};

export const toStaticAssetUrl = (assetUrl: string) => {
  return replaceUrlPath({
    assetUrl,
    newPath: 'nft.jpg',
  });
};
/**
 * Define a prefix for a data URI indicating it contains JSON content.
 */
const prefix = 'data:application/json;utf8,';

/**
 * Defines a lightweight zod schema for the expected structure of NFT data
 */
const nftSchema = z.object({
  image: z.string(),
  name: z.string(),
});

/**
 * Parse and validate a data URI containing NFT data.
 *
 * @param dataUri - The data URI string containing a JSON representation of the NFT data.
 * @returns - The parsed and validated NFT data.
 *
 * - Extracts the JSON content from the data URI by removing the prefix.
 * - Parses the JSON string into a JavaScript object.
 * - Validates the parsed object against the `nftSchema`.
 */
export const parseNftJson = (dataUri: string) => {
  const jsonData = dataUri.substring(prefix.length);
  const obj = JSON.parse(jsonData);
  return nftSchema.parse(obj);
};
