import { MultipleQueriesQuery } from '@algolia/client-search';

export function buildAlgoliaSearchQuery(
  searchTerm: string
): MultipleQueriesQuery[] {
  return [
    {
      indexName: 'collections',
      query: searchTerm,
      params: {
        hitsPerPage: 3,
        facetFilters: ['isHidden:false', 'isDeleted:false'],
      },
    },
    {
      indexName: 'users',
      query: searchTerm,
      params: {
        hitsPerPage: 3,
        facetFilters: ['moderationStatus:ACTIVE', 'isHidden:false'],
      },
    },
    {
      indexName: 'artworks',
      query: searchTerm,
      params: {
        hitsPerPage: 3,
        facetFilters: [
          'moderationStatus:ACTIVE',
          'isDeleted:false',
          'isHidden:false',
        ],
      },
    },
  ];
}
