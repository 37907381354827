import { styled } from '@f8n-frontend/stitches';

import Grid from 'components/base/Grid';

const SearchResultsGrid = styled(Grid, {
  backgroundColor: '$white100',
  padding: '$2',
  paddingTop: '$5',
});

export default SearchResultsGrid;
