import { cond, equals, always } from 'ramda';

import Box from 'components/base/Box';

import { ModerationStatus } from 'types/Moderation';

import WarningBlock from './WarningBlock';
import WarningTermsLink from './WarningTermsLink';

interface TransactionWarningBlockProps {
  moderationStatus: ModerationStatus;
}

export default function TransactionWarningBlock(
  props: TransactionWarningBlockProps
) {
  const { moderationStatus } = props;

  return cond<ModerationStatus, JSX.Element>([
    [
      (status) => equals('SUSPENDED', status),
      always(
        <WarningBlock
          title="Your profile has been permanently removed."
          description={
            <Box>
              Your profile has been found to be in violation of the Foundation{' '}
              <WarningTermsLink variant="strong" /> and permanently suspended.
              You can no longer mint any new NFTs.
            </Box>
          }
          status="SUSPENDED"
        />
      ),
    ],
    [
      (status) => equals('UNDER_REVIEW', status),
      always(
        <WarningBlock
          title="Your profile is under review."
          description={
            <Box>
              Your profile is currently under review by the Foundation team, to
              ensure it has not broken the Foundation{' '}
              <WarningTermsLink variant="strong" />. You will not be able to
              mint any new NFTs at this time.
            </Box>
          }
          status="UNDER_REVIEW"
        />
      ),
    ],
  ])(moderationStatus);
}
