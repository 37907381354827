import FNDLogo from 'assets/images/fnd-logo.svg';

import { UnsafeAny } from 'types/utils';

import Box from './base/Box';

interface LogoProps {
  css: UnsafeAny;
}

export default function Logo(props: LogoProps) {
  const { css } = props;

  return (
    <Box
      css={{
        ...css,
        paddingBottom: '33.6734694%',
        height: 0,
      }}
    >
      <FNDLogo style={{ display: 'block' }} />
    </Box>
  );
}
