import {
  AlgoliaMultipleSearchResponse,
  GlobalSearchResults,
} from 'types/Search';

export const parseAlgoliaMultipleSearchResults = (
  data: AlgoliaMultipleSearchResponse | null
): Omit<GlobalSearchResults, 'worlds' | 'moments'> => {
  if (!data || !data.results) {
    return {
      artworks: {
        items: [],
      },
      collections: {
        items: [],
      },
      users: {
        items: [],
      },
    };
  }

  const [collections, users, artworks] = data.results;

  return {
    artworks: {
      items: artworks.hits,
    },
    collections: {
      items: collections.hits,
    },
    users: {
      items: users.hits,
    },
  };
};
