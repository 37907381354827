import { styled } from '@f8n-frontend/stitches';

import Flex from 'components/base/Flex';
import Grid from 'components/base/Grid';
import Heading from 'components/base/Heading';
import Text from 'components/base/Text';

import SearchIcon from 'assets/icons/search-icon.svg';

interface SearchEmptyStateProps {
  heading: string;
  description: string;
  headingSize: 3 | 4;
}

export default function SearchEmptyState(props: SearchEmptyStateProps) {
  const { heading, description, headingSize } = props;
  return (
    <Grid css={{ gap: '$4' }}>
      <Grid css={{ gap: '$7', justifyContent: 'center' }}>
        <SearchIcon
          width={20}
          height={20}
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
        <Heading size={headingSize} lineHeight={1}>
          {heading}
        </Heading>
      </Grid>
      <Text
        css={{
          maxWidth: 360,
          marginLeft: 'auto',
          marginRight: 'auto',
          lineHeight: '$2',
          textAlign: 'center',
          color: '$black50',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {description}
      </Text>
    </Grid>
  );
}

export const EmptyStateContainer = styled(Flex, {
  alignItems: 'center',
  flexDirection: 'column',
  paddingTop: '$10',
  paddingBottom: '$10',
  gap: '$4',
});
