import { ProductCategory } from 'types/product-category';

type Branding = {
  /**
   * Used when referencing an instance of this product (View gallery, Edit gallery, etc)
   *
   * @example gallery */
  instance: string;
  /** @example Gallery */
  singular: string;
  /** @example Galleries */
  plural: string;
};

const enforceBranding = <T extends Branding>(options: T) => options;
const enforceProductBranding = <T extends Record<ProductCategory, Branding>>(
  options: T
) => options;

export const DROP_BRANDING = enforceBranding({
  instance: 'drop',
  plural: 'Drops',
  singular: 'Drop',
} as const);

export const EDITION_COLLECTION_BRANDING = enforceBranding({
  instance: 'edition',
  singular: 'Edition',
  plural: 'Editions',
} as const);

export const NFT_BRANDING = enforceBranding({
  instance: 'NFT',
  singular: 'NFT',
  plural: 'NFTs',
} as const);

export const MOMENT_BRANDING = enforceBranding({
  instance: 'exhibition',
  singular: 'Exhibition',
  plural: 'Exhibitions',
} as const);

export const EDITION_TOKEN_BRANDING = enforceBranding({
  instance: 'open edition',
  singular: 'Open Edition',
  plural: 'Open Editions',
} as const);

export const WORLD_BRANDING = enforceBranding({
  instance: 'gallery',
  singular: 'Gallery',
  plural: 'Galleries',
} as const);

export const PRODUCT_BRANDING = enforceProductBranding({
  drop: DROP_BRANDING,
  editionCollection: EDITION_COLLECTION_BRANDING,
  editionToken: EDITION_TOKEN_BRANDING,
  moment: MOMENT_BRANDING,
  nft: NFT_BRANDING,
  world: WORLD_BRANDING,
} as const);
