import { styled } from '@f8n-frontend/stitches';

import Flex from 'components/base/Flex';

const SearchResultElement = styled(Flex, {
  minHeight: '66px', // To avoid jumping when no username is present
  textDecoration: 'none',
  alignItems: 'center',
  borderRadius: '$2',
  transition: 'background-color $0 $ease',
  padding: '$2 $3',
  minWidth: 0,
  variants: {
    hoverable: {
      true: {
        '@hover': {
          '&:hover': {
            backgroundColor: '$black5',
          },
        },
      },
    },
  },

  defaultVariants: {
    hoverable: true,
  },
});

export default SearchResultElement;
