import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import * as Generated from 'gql/api/queries/has-worlds.generated';
import { getFirstValue } from 'utils/helpers';

type WorldDashboard = {
  id: number;
};

async function getWorldDashboard(
  variables: Generated.ApiHasWorldsVariables
): Promise<WorldDashboard | null> {
  const fetchWorldDashboard = Generated.useHasWorlds.fetcher(variables);
  const response = await fetchWorldDashboard();
  const world = getFirstValue(response.worlds);

  return world ? world : null;
}

export default function useWorldDashboard(
  variables: Generated.ApiHasWorldsVariables,
  options?: UseQueryOptions<WorldDashboard | null, Error>
) {
  return useQuery({
    ...options,
    queryKey: ['world-dashboard', variables],
    queryFn: () => getWorldDashboard(variables),
  });
}
