import { styled } from '@f8n-frontend/stitches';

import ToggleButton from 'components/base/ToggleButton';

const HeaderButton = styled(ToggleButton, {
  minWidth: 0,
  paddingX: '$5',
  display: 'none',
  '@bp2': {
    display: 'flex',
    alignItems: 'center',
  },
  variants: {
    size: {
      0: {},
      1: {},
      2: {},
    },
    variant: {
      ghost: {},
    },
    isActive: {
      true: {},
    },
    isDark: {
      true: {
        color: '$white100',
        '&:hover': {
          color: '$white100',
          backgroundColor: '$white20',
          backdropFilter: 'blur(10px)',
        },
      },
      false: {
        color: '$black70',
        '&:hover': {
          color: '$black100',
          background: '$black5',
        },
      },
    },
  },
  compoundVariants: [
    {
      isActive: true,
      isDark: false,
      css: {
        color: '$black100',
      },
    },
    {
      isActive: true,
      isDark: true,
      css: {
        color: '$white100',
      },
    },
  ],
  defaultVariants: {
    variant: 'ghost',
    size: 0,
  },
});

export default HeaderButton;
