import { styled } from '@f8n-frontend/stitches';

import ChevronButton from 'components/base/ChevronButton';

const HeaderChevronButton = styled(ChevronButton, {
  minWidth: 0,
  cursor: 'default',

  variants: {
    isDark: {
      true: {
        color: '$white100',
        '&:after': {
          backgroundColor: '$white80',
        },
        '&:active': {
          backgroundColor: '$white20',
        },
        '@hover': {
          '&:hover': {
            color: '$white100',
            backgroundColor: '$white20',
            backdropFilter: 'blur(10px)',
          },
        },
        "&[aria-expanded='true']": {
          color: '$white100',
          backgroundColor: '$white20',
          backdropFilter: 'blur(10px)',
          '&:active': {
            backgroundColor: '$white20',
          },
          '&:after': {
            backgroundColor: '$white100',
          },
        },
      },
      false: {
        color: '$black70',
        '&:active': {
          background: '$black5',
        },
        '&:after': {
          backgroundColor: '$black70',
        },
        "&[aria-expanded='true']": {
          color: '$black100',
          background: '$black5',
          '&:after': {},
        },
      },
    },
  },
});

HeaderChevronButton.defaultProps = {
  size: 0,
  animateIcon: false,
  variant: 'ghost',
};

export default HeaderChevronButton;
