import { onGridPx } from '@f8n/tokens';
import { styled } from '@f8n-frontend/stitches';
import { useEffect, useRef, memo, Dispatch, SetStateAction } from 'react';
import { useKeyPress } from 'react-use';

import Box from 'components/base/Box';
import Button from 'components/base/Button';
import Input from 'components/base/Input';

import CloseIcon from 'assets/icons/close-icon.svg';
import SearchIcon from 'assets/icons/search-icon.svg';

import { PageColorMode } from 'types/page';

import SearchBarIcon from './search-result/SearchBarIcon';

interface SearchBarInputProps {
  setSearchTerm: Dispatch<SetStateAction<string>>;
  setIsFocused: Dispatch<SetStateAction<boolean>>;
  placeholder: string;
  title: string;
  isFocused: boolean;
  colorMode: PageColorMode;
  searchOpen: boolean;
  searchTerm: string;
}

export default memo(SearchBarInput);

function SearchBarInput(props: SearchBarInputProps) {
  const {
    setSearchTerm,
    setIsFocused,
    placeholder,
    title,
    isFocused,
    colorMode,
    searchOpen,
    searchTerm,
  } = props;

  const focusRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (searchOpen) {
      focusRef?.current?.focus();
    }
  }, [searchOpen]);

  const isEscapePressed = useKeyPress('Escape');

  useEffect(() => {
    if (isEscapePressed) {
      focusRef?.current?.blur();
      setIsFocused(false);
    }
  }, [isEscapePressed, setIsFocused]);

  const iconMode = colorMode === 'DARK' ? 'white' : 'black';

  return (
    <Box
      css={{
        position: 'relative',
        zIndex: 999,
        '@bp0': {
          marginX: '$3',
        },
      }}
    >
      <Input
        css={{
          border: 'none',
          width: '100%',
          paddingRight: onGridPx(isFocused ? 28 : 8),
          truncate: true,
          fontSize: '$1',
          paddingLeft: '$8',
          minHeight: '$formElement1',
        }}
        data-active={isFocused}
        size="large"
        color={colorMode === 'DARK' ? 'translucent' : 'gray'}
        value={searchTerm}
        onChange={(ev) => setSearchTerm(ev.target.value)}
        onFocus={() => setIsFocused(true)}
        placeholder={placeholder}
        title={title}
        ref={focusRef}
      />
      <SearchBarIcon
        css={{
          pointerEvents: 'none',
          left: onGridPx(5),
        }}
        color={iconMode}
        data-active={isFocused}
      >
        <SearchIcon width={16} height={16} style={{ display: 'block' }} />
      </SearchBarIcon>
      {isFocused && (
        <SearchControlsWrapper>
          {searchTerm && (
            <Button
              css={{ paddingX: '$3' }}
              onClick={() => setSearchTerm('')}
              size={0}
              variant="ghost"
            >
              Clear
            </Button>
          )}
          <Button
            icon="standalone"
            onClick={() => {
              setIsFocused(false);
              setSearchTerm('');
            }}
            size={0}
            variant="ghost"
            css={{
              display: 'none',
              '@bp2': {
                display: 'flex',
              },
            }}
          >
            <CloseIcon />
          </Button>
        </SearchControlsWrapper>
      )}
    </Box>
  );
}

const SearchControlsWrapper = styled('div', {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: '$2',
  display: 'flex',
  alignItems: 'center',

  // Tweak internal buttons. We usually want buttons to align with inputs, but here they should fit inside.
  button: {
    height: onGridPx(8),
    minWidth: onGridPx(8),
    width: 'auto',
    color: '$black50',

    svg: {
      width: 14,
      height: 14,
    },
  },
});
