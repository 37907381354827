import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import * as Generated from 'gql/api/queries/world-role.generated';

import { WorldRole } from 'types/World';

async function getWorldRole(variables: { publicKey: string; worldId: number }) {
  const fetchWorldRole = Generated.useWorldRole.fetcher(variables);
  const worldRoleResponse = await fetchWorldRole();

  const isOwner = worldRoleResponse.world
    ? worldRoleResponse.world.curator.publicKey === variables.publicKey
    : false;

  return isOwner ? 'OWNER' : worldRoleResponse.worldRole;
}

export default function useWorldRole(
  variables: {
    publicKey: string;
    worldId: number;
  },
  options: Pick<UseQueryOptions<WorldRole | null, Error>, 'enabled'> = {}
) {
  return useQuery({
    ...options,
    queryKey: ['world-role', variables],
    queryFn: () => getWorldRole(variables),
  });
}
