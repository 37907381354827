import { styled } from '@f8n-frontend/stitches';
import { ReactNode } from 'react';

import { ApiMomentSearchItemFragment } from 'gql/api/api-fragments.generated';
import { hasUsername, truncateAddress } from 'utils/helpers';
import { getDpr, optimizeAsset } from 'utils/imgix';

import { UserMicro } from 'types/Account';
import { WorldOverview } from 'types/World';

import Media from './Media';
import Box from './base/Box';
import Mono from './base/Mono';
import Skeleton from './base/Skeleton';
import Text from './base/Text';
import UserAvatar from './base/UserAvatar';
import WorldLogo from './base/WorldLogo';
import UserText from './users/UserText';

interface DetailsTagScaffoldingProps {
  name: ReactNode;
  parent: ReactNode;
  media: ReactNode;
}

function DetailsTagScaffolding(props: DetailsTagScaffoldingProps) {
  return (
    <Box
      css={{ display: 'flex', minWidth: 0, gap: '$3', alignItems: 'center' }}
    >
      {props.media}
      <Box
        css={{
          minWidth: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <>{props.name}</>
        <>{props.parent}</>
      </Box>
    </Box>
  );
}

interface DetailsTagMomentProps {
  moment: Pick<
    ApiMomentSearchItemFragment,
    'name' | 'posterUrl' | 'worldImageUrl' | 'worldName'
  >;
}

function DetailsTagMoment(props: DetailsTagMomentProps) {
  const { moment } = props;

  return (
    <DetailsTagScaffolding
      name={<DetailsTagText as="span">{moment.name}</DetailsTagText>}
      parent={
        <InlineTagWorld
          imageUrl={moment.worldImageUrl}
          name={moment.worldName}
        />
      }
      media={
        moment.posterUrl ? (
          <Media.Root
            css={{ flexShrink: 0, borderRadius: '$2', width: 40, height: 40 }}
          >
            <Media.Tint edge="darken">
              <Media
                alt=""
                src={optimizeAsset(moment.posterUrl, {
                  w: 40,
                  h: 40,
                  fit: 'crop',
                  auto: undefined,
                  dpr: getDpr(),
                })}
              />
            </Media.Tint>
          </Media.Root>
        ) : (
          <Skeleton.Block css={{ width: 40, height: 40 }} />
        )
      }
    />
  );
}

interface DetailsTagUserProps {
  user: UserMicro;
}

function DetailsTagUser(props: DetailsTagUserProps) {
  const { user } = props;
  const { profileImageUrl, publicKey } = user;

  return (
    <DetailsTagScaffolding
      media={
        <UserAvatar imageUrl={profileImageUrl} publicKey={publicKey} size={4} />
      }
      parent={
        // prevents duplicate public keys from showing
        hasUsername(user) && (
          <Mono size={1} color="dim">
            {truncateAddress(publicKey)}
          </Mono>
        )
      }
      name={
        <UserText
          variant="prefer-username"
          user={user}
          color="strong"
          size={1}
        />
      }
    />
  );
}

interface DetailsTagWorldProps {
  world: Pick<WorldOverview, 'imageUrl' | 'name'>;
}

function DetailsTagWorld(props: DetailsTagWorldProps) {
  const { world } = props;
  const { imageUrl, name } = world;

  return (
    <DetailsTagScaffolding
      media={
        imageUrl ? (
          <WorldLogo imageUrl={imageUrl} size={4} />
        ) : (
          <WorldLogo.Skeleton size={4} />
        )
      }
      parent={null}
      name={
        <DetailsTagText size={1} css={{ textAlign: 'left' }}>
          {name}
        </DetailsTagText>
      }
    />
  );
}

interface InlineTagWorldProps {
  imageUrl: string | null;
  name: string;
}

function InlineTagWorld(props: InlineTagWorldProps) {
  const { imageUrl, name } = props;
  return (
    <Box css={{ display: 'flex', gap: '$1' }}>
      {imageUrl ? <WorldLogo imageUrl={imageUrl} size={0} /> : null}
      <DetailsTagText size={1} weight="medium" color="dim">
        {name}
      </DetailsTagText>
    </Box>
  );
}

const DetailsTagText = styled(Text, {
  textAlign: 'left',
  display: 'block',
  truncate: true,
});

DetailsTagText.defaultProps = {
  size: 2,
  weight: 'semibold',
  color: 'strong',
  lineHeight: 1,
};

const DetailsTag = {
  Moment: DetailsTagMoment,
  User: DetailsTagUser,
  World: DetailsTagWorld,
};

export default DetailsTag;
