import {
  MultipleQueriesQuery,
  SearchOptions,
  SearchResponse,
} from '@algolia/client-search';
import { SearchIndex } from 'algoliasearch/lite';

import algoliaClient from 'lib/clients/algolia';

import { AlgoliaFacet, AlgoliaSearchIndex } from 'types/Algolia';

const searchIndexes: Record<AlgoliaSearchIndex, SearchIndex> = {
  artworks_sort_auction_live_price_asc: algoliaClient.initIndex(
    'artworks_sort_auction_live_price_asc'
  ),
  artworks_sort_auction_live_price_desc: algoliaClient.initIndex(
    'artworks_sort_auction_live_price_desc'
  ),
  artworks_sort_auction_reserve_price_asc: algoliaClient.initIndex(
    'artworks_sort_auction_reserve_price_asc'
  ),
  artworks_sort_auction_reserve_price_desc: algoliaClient.initIndex(
    'artworks_sort_auction_reserve_price_desc'
  ),
  artworks_sort_buy_now_price_asc: algoliaClient.initIndex(
    'artworks_sort_buy_now_price_asc'
  ),
  artworks_sort_buy_now_price_desc: algoliaClient.initIndex(
    'artworks_sort_buy_now_price_desc'
  ),
  artworks_sort_date_minted_asc: algoliaClient.initIndex(
    'artworks_sort_date_minted_asc'
  ),
  artworks_sort_date_minted_desc: algoliaClient.initIndex(
    'artworks_sort_date_minted_desc'
  ),
  artworks_sort_default_price_asc: algoliaClient.initIndex(
    'artworks_sort_default_price_asc'
  ),
  artworks_sort_default_price_desc: algoliaClient.initIndex(
    'artworks_sort_default_price_desc'
  ),
  artworks_sort_default: algoliaClient.initIndex('artworks_sort_default'),
  artworks_sort_offer_price_asc: algoliaClient.initIndex(
    'artworks_sort_offer_price_asc'
  ),
  artworks_sort_offer_price_desc: algoliaClient.initIndex(
    'artworks_sort_offer_price_desc'
  ),
  collections: algoliaClient.initIndex('collections'),
  collections_sort_date_created_asc: algoliaClient.initIndex(
    'collections_sort_date_created_asc'
  ),
  collections_sort_date_created_desc: algoliaClient.initIndex(
    'collections_sort_date_created_desc'
  ),
  collections_sort_date_last_minted_to_desc: algoliaClient.initIndex(
    'collections_sort_date_last_minted_to_desc'
  ),
  users: algoliaClient.initIndex('users'),
};

export const defaultSearchIndexes: Record<'collections', AlgoliaSearchIndex> = {
  collections: 'collections_sort_date_last_minted_to_desc',
};

type FacetFilterString = `${AlgoliaFacet}:${string}`;
type FacetFilter = FacetFilterString | '';

export interface AlgoliaSearchIndexOptions extends SearchOptions {
  facets?: readonly AlgoliaFacet[];
  facetFilters?: readonly (FacetFilter | FacetFilter[])[];
}

interface AlgoliaSearchOptions {
  index: AlgoliaSearchIndex;
  query: string;
  options: AlgoliaSearchIndexOptions;
}

export async function getAlgoliaSearchResults<T>({
  index,
  query,
  options,
}: AlgoliaSearchOptions): Promise<SearchResponse<T>> {
  const searchIndex = searchIndexes[index];
  return await searchIndex.search(query, options);
}

export async function getAlgoliaMultipleSearchResults<T>(
  query: MultipleQueriesQuery[]
): Promise<T> {
  // algolia’s types can only handle results from a
  // single index so we need to manually override it
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return await algoliaClient.search(query);
}
