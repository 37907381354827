import { styled } from '@f8n-frontend/stitches';
import { VariantProps } from '@stitches/react';

import Avatar, { AvatarProps } from 'components/base/Avatar';
import Skeleton from 'components/base/Skeleton';

import { AVATAR_BORDERS, AVATAR_RADII } from 'utils/avatar';

type BorderProps = VariantProps<typeof CollectionLogoOversizedBorder>;
type Variant = BorderProps['variant'];

type CollectionLogoProps = Omit<AvatarProps, 'shape'> & {
  variant?: Variant;
};

function CollectionLogoBase(props: CollectionLogoProps) {
  const { variant, size, ...rest } = props;

  if (variant === 'base') {
    return <Avatar size={size} shape="square" {...rest} />;
  }

  return (
    <CollectionLogoOversizedBorder variant={variant} size={size}>
      <Avatar size={size} shape="square" {...rest} />
    </CollectionLogoOversizedBorder>
  );
}
CollectionLogoBase.defaultProps = {
  variant: 'base',
};

function CollectionLogoSkeleton(
  props: Pick<CollectionLogoProps, 'size' | 'variant'>
) {
  const { variant, size } = props;

  if (variant === 'base') {
    return <Skeleton.Avatar size={size} shape="square" />;
  }

  return (
    <CollectionLogoOversizedBorder variant={variant} size={size}>
      <Skeleton.Avatar size={size} shape="square" />
    </CollectionLogoOversizedBorder>
  );
}

const CollectionLogoOversizedBorder = styled('div', {
  variants: {
    variant: {
      // This is added here to simplify the typing of the parent
      base: {},
      blur: {
        backgroundColor: '$white20',
        backdropFilter: 'blur(10px)',
        [`${Avatar.Image}`]: {
          boxShadow: 'none',
        },
        [`${Skeleton.Avatar}`]: {
          backdropFilter: 'blur(10px)',
        },
      },
      raised: {
        boxShadow: '$soft1',
        backgroundColor: '$white100',
      },
      white: {
        backgroundColor: '$white100',
      },
    },
    size: {
      0: {
        padding: `${AVATAR_BORDERS[0]}px`,
        borderRadius: `calc(${AVATAR_BORDERS[0]}px + ${AVATAR_RADII[0]}px)`,
      },
      1: {
        padding: `${AVATAR_BORDERS[1]}px`,
        borderRadius: `calc(${AVATAR_BORDERS[1]}px + ${AVATAR_RADII[1]}px)`,
      },
      2: {
        padding: `${AVATAR_BORDERS[2]}px`,
        borderRadius: `calc(${AVATAR_BORDERS[2]}px + ${AVATAR_RADII[2]}px)`,
      },
      3: {
        padding: `${AVATAR_BORDERS[3]}px`,
        borderRadius: `calc(${AVATAR_BORDERS[3]}px + ${AVATAR_RADII[3]}px)`,
      },
      4: {
        padding: `${AVATAR_BORDERS[4]}px`,
        borderRadius: `calc(${AVATAR_BORDERS[4]}px + ${AVATAR_RADII[4]}px)`,
      },
      5: {
        padding: `${AVATAR_BORDERS[5]}px`,
        borderRadius: `calc(${AVATAR_BORDERS[5]}px + ${AVATAR_RADII[5]}px)`,
      },
      6: {
        padding: `${AVATAR_BORDERS[6]}px`,
        borderRadius: `calc(${AVATAR_BORDERS[6]}px + ${AVATAR_RADII[6]}px)`,
      },
      7: {
        padding: `${AVATAR_BORDERS[7]}px`,
        borderRadius: `calc(${AVATAR_BORDERS[7]}px + ${AVATAR_RADII[7]}px)`,
      },
      8: {
        padding: `${AVATAR_BORDERS[8]}px`,
        borderRadius: `calc(${AVATAR_BORDERS[8]}px + ${AVATAR_RADII[8]}px)`,
      },
      9: {
        padding: `${AVATAR_BORDERS[9]}px`,
        borderRadius: `calc(${AVATAR_BORDERS[9]}px + ${AVATAR_RADII[9]}px)`,
      },
    },
  },
  defaultVariants: {
    variant: 'base',
  },
});

const CollectionLogo = Object.assign(CollectionLogoBase, {
  Skeleton: CollectionLogoSkeleton,
});

export default CollectionLogo;
