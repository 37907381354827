import { ArrowLeftIcon } from '@f8n/icons';

import Button, { ButtonProps } from 'components/base/Button';

interface BackButtonProps {
  css?: ButtonProps['css'];
  isDisabled?: boolean;
  onClick: () => void;
  size?: ButtonProps['size'];
  variant?: ButtonProps['variant'];
}

export default function BackButton(props: BackButtonProps) {
  const { onClick, isDisabled = false, ...rest } = props;
  return (
    <Button
      icon="standalone"
      onClick={onClick}
      type="button"
      variant="outline"
      size={1}
      disabled={isDisabled}
      {...rest}
    >
      <ArrowLeftIcon />
    </Button>
  );
}
