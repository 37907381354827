import { IS_TESTNET, IS_PREVIEW } from './env';

type ColorMode = 'light' | 'dark';
type FaviconSize = 16 | 32;

type FaviconName =
  | `app-${ColorMode}`
  | `app-preview-${ColorMode}`
  | 'testnet'
  | 'testnet-preview';

type Favicons = Record<ColorMode, FaviconName>;

export const getFaviconNames = (): Favicons => {
  if (IS_TESTNET) {
    if (IS_PREVIEW) {
      return {
        // No need to swap this on dark/light mode
        dark: 'testnet-preview',
        light: 'testnet-preview',
      };
    } else {
      return {
        // No need to swap this on dark/light mode
        dark: 'testnet',
        light: 'testnet',
      };
    }
  }

  if (IS_PREVIEW) {
    return {
      dark: 'app-preview-dark',
      light: 'app-preview-light',
    };
  } else {
    return {
      dark: 'app-dark',
      light: 'app-light',
    };
  }
};

export const getFaviconHref = (size: FaviconSize, name: FaviconName) => {
  return `/favicons/${size}x${size}/${name}.png`;
};
