import { styled } from '@f8n-frontend/stitches';

import Heading from 'components/base/Heading';

const SearchResultHeading = styled(Heading, {});

SearchResultHeading.defaultProps = {
  size: 2,
  ellipsis: true,
  color: 'strong',
  lineHeight: 1,
};

export default SearchResultHeading;
