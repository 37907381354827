import { styled } from '@f8n-frontend/stitches';

const StatusDot = styled('div', {
  width: '8px',
  height: '8px',
  borderRadius: '$round',
  variants: {
    color: {
      red: {
        backgroundColor: '$red3',
      },
      green: {
        backgroundColor: '$green4',
      },
    },
  },
  defaultVariants: {
    color: 'red',
  },
});

export default StatusDot;
