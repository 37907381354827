import NextLink from 'next/link';

import CollectionLogo from 'components/base/CollectionLogo';
import Grid from 'components/base/Grid';
import Mono from 'components/base/Mono';

import { buildCollectionPath } from 'utils/collections';

import type { AlgoliaCollection } from 'types/Algolia';

import SearchResultElement from './search-result/SearchResultElement';
import SearchResultHeading from './search-result/SearchResultHeading';

interface SearchHitCollectionProps {
  collection: AlgoliaCollection;
  onClick: (arg0: AlgoliaCollection) => void;
}

export default function SearchHitCollection(props: SearchHitCollectionProps) {
  const { collection, onClick } = props;

  return (
    <NextLink href={buildCollectionPath(collection)} prefetch={false} passHref>
      <SearchResultElement as="a" onClick={() => onClick(collection)}>
        <CollectionLogo
          imageUrl={collection.collectionImageUrl}
          css={{ marginRight: '$4' }}
          size={4}
        />
        <Grid css={{ alignItems: 'center', gap: '$1' }}>
          <SearchResultHeading>{collection.name}</SearchResultHeading>
          <Mono color="dim" size={1}>
            {collection.symbol}
          </Mono>
        </Grid>
      </SearchResultElement>
    </NextLink>
  );
}
