import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useMomentsSearch } from 'gql/api/queries/moments-search.generated';
import { useWorldsSearch } from 'gql/api/queries/worlds-search.generated';
import { parseAlgoliaMultipleSearchResults } from 'schemas/parse/search';
import { getPromiseValueOrFallback } from 'utils/helpers';

import {
  AlgoliaMultipleSearchResponse,
  GlobalSearchResults,
} from 'types/Search';

import { getAlgoliaMultipleSearchResults } from './algolia/shared';
import { buildAlgoliaSearchQuery } from './algolia/use-algolia-search';

interface GlobalSearchVariables {
  searchTerm: string;
}

export default function useGlobalSearch(
  variables: GlobalSearchVariables,
  options: Pick<
    UseQueryOptions<GlobalSearchResults, Error>,
    'enabled' | 'placeholderData'
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: useGlobalSearch.getKey(variables),
    queryFn: () => getGlobalSearch(variables),
  });
}

useGlobalSearch.getKey = (variables: GlobalSearchVariables) => [
  'GlobalSearch',
  variables,
];

async function getGlobalSearch(
  props: GlobalSearchVariables
): Promise<GlobalSearchResults> {
  const { searchTerm } = props;

  const algoliaSearchQuery = buildAlgoliaSearchQuery(searchTerm);

  /**
   * Our search results are currently partially returned from Algolia, and partially from the API
   * By using Promise.allSettled, we can fetch both in parallel, and handle scenarios where one fails.
   * */
  const [algoliaPromiseResult, worldPromiseResult, momentPromiseResult] =
    await Promise.allSettled([
      getAlgoliaMultipleSearchResults<AlgoliaMultipleSearchResponse>(
        algoliaSearchQuery
      ),
      getWorldsSearch({ searchTerm }),
      getMomentsSearch({ searchTerm }),
    ]);

  /** Parse promise results, falling back to null if any were rejected  */
  const algoliaResults = getPromiseValueOrFallback(algoliaPromiseResult, null);
  const worldResults = getPromiseValueOrFallback(worldPromiseResult, null);
  const momentResults = getPromiseValueOrFallback(momentPromiseResult, null);
  /** Transform all results into a normalized format */
  const algoliaData = parseAlgoliaMultipleSearchResults(algoliaResults);
  const worlds = {
    items: worldResults ? worldResults : [],
  };
  const moments = {
    items: momentResults ? momentResults : [],
  };

  /** Return data in a normalized format */
  return {
    artworks: algoliaData.artworks,
    collections: algoliaData.collections,
    users: algoliaData.users,
    worlds,
    moments,
  };
}

async function getWorldsSearch(variables: GlobalSearchVariables) {
  const fetchWorlds = useWorldsSearch.fetcher(variables);
  const data = await fetchWorlds();

  if (!data) return [];

  return data.search.worlds;
}

async function getMomentsSearch(variables: GlobalSearchVariables) {
  const fetchWorlds = useMomentsSearch.fetcher(variables);
  const data = await fetchWorlds();

  if (!data) return [];

  return data.momentsSearch.items;
}
