import {
  ActivityIcon,
  BaseIcon,
  DisconnectIcon,
  EthereumIcon,
  ExternalIcon,
  GridIcon,
  HelpIcon,
  NotificationsIcon,
  SettingsIcon,
} from '@f8n/icons';
import { onGrid } from '@f8n/tokens';
import { styled } from '@f8n-frontend/stitches';
import { match } from 'ts-pattern';
import { useSwitchChain } from 'wagmi';

import StatusDot from 'components/StatusDot';
import Avatar from 'components/base/Avatar';
import Box from 'components/base/Box';
import Button from 'components/base/Button';
import DropdownMenu from 'components/base/DropdownMenu';
import Link from 'components/base/Link';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';
import Tooltip from 'components/base/Tooltip';
import useAnalytics from 'contexts/analytics/useAnalytics';
import {
  getConnectedUsernameOrPublicKey,
  hasUser,
} from 'contexts/auth/helpers';
import { AuthConnected, AuthEnriched } from 'contexts/auth/types';
import { getChangeNetworkCopy } from 'copy/auth';

import { useHasWorldNotifications } from 'gql/api/queries/has-world-notifications.generated';
import { useNotificationsCount } from 'gql/api/queries/notifications-count.generated';
import useWorldDashboard from 'hooks/queries/api/useWorldDashboard';
import useUserActivityCount from 'hooks/queries/hasura/notifications/use-user-activity-count';
import useModal from 'hooks/use-modal';
import useIsUnsupportedNetwork from 'hooks/web3/use-is-unsupported-network';
import useMarketBalances from 'hooks/web3/use-market-balances';
import useDisconnectWallet from 'hooks/web3/wallet/use-disconnect-wallet';
import { CHAINS, ChainId } from 'lib/chains';
import { ENV } from 'utils/env';
import { getEtherscanUrl } from 'utils/etherscan';
import { formatBigIntEthWithSuffix } from 'utils/formatters';
import { formatUsername } from 'utils/helpers';
import { truncateStringCenter } from 'utils/helpers';
import { getChainConfigById, isBaseChainConfig } from 'utils/network';
import { isNonZero } from 'utils/numbers';
import { HELP_CENTER_HOME, getPath } from 'utils/router';

interface UserDropdownProps {
  auth: AuthConnected | AuthEnriched;
  hasDarkBackground?: boolean;
}

export default function UserDropdown(props: UserDropdownProps) {
  const { auth, hasDarkBackground } = props;
  const { publicKey } = auth;
  const user = hasUser(auth) ? auth.user : null;

  const { setModal } = useModal();

  const marketBalancesQuery = useMarketBalances({ chainId: auth.chainId });

  const { data: activityCount } = useUserActivityCount({ publicKey });

  const { mutate: disconnectWallet } = useDisconnectWallet();

  const usernameOrAddress = getConnectedUsernameOrPublicKey(auth);
  const profileHref = getPath.profile.page(usernameOrAddress);
  const isUnsupportedNetwork = useIsUnsupportedNetwork();
  const etherscanUrl = getEtherscanUrl.address(publicKey, {
    chainId: auth.chainId,
  });
  const { switchChain } = useSwitchChain({
    mutation: {
      onSuccess: () => {
        marketBalancesQuery.refetch();
      },
    },
  });
  const hasWorldsQuery = useWorldDashboard({ publicKey });
  const hasWorldsNotificationsQuery = useHasWorldNotifications(
    {
      publicKey,
    },
    {
      enabled: Boolean(hasWorldsQuery.data?.id),
    }
  );

  const hasWorldsNotifications = Boolean(
    hasWorldsNotificationsQuery.data?.hasWorldNotifications
  );

  const worldId = hasWorldsQuery.data?.id;
  const notificationsCount = useNotificationsCount();
  const unreadCount = notificationsCount.data
    ? notificationsCount.data.notificationsCount
    : 0;
  const analytics = useAnalytics();

  const getNetworkStatusBadge = () => {
    if (isUnsupportedNetwork) {
      const children = (
        <>
          Wrong network
          <StatusDot color="red" />
        </>
      );

      const changeNetworkCopy = getChangeNetworkCopy();

      return (
        <Tooltip content={changeNetworkCopy.heading}>
          <NetworkStatus
            as="button"
            onClick={() => switchChain({ chainId: ENV.PRIMARY_CHAIN_ID })}
          >
            {children}
          </NetworkStatus>
        </Tooltip>
      );
    }

    if (publicKey && etherscanUrl) {
      return (
        <NetworkStatus
          as={Link}
          href={etherscanUrl}
          mono
          target="_blank"
          variant="primary"
        >
          {truncateStringCenter(4, publicKey)}
          <StatusDot color="green" />
        </NetworkStatus>
      );
    }

    return null;
  };

  const chainConfig = getChainConfigById(auth.chainId);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Button
          aria-label="Toggle user menu"
          icon="standalone"
          size={0}
          variant={hasDarkBackground ? 'blur' : 'secondary'}
        >
          <Avatar
            imageUrl={user ? user.profileImageUrl : null}
            publicKey={publicKey}
            size={2}
          />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="center"
          minWidth={300}
          sideOffset={onGrid(3)}
        >
          <DropdownMenu.Item type="link" href={profileHref}>
            <Box css={{ marginLeft: '-$1' }}>
              <Avatar
                imageUrl={user ? user.profileImageUrl : null}
                publicKey={publicKey}
                size={4}
              />
            </Box>
            <Box>
              <Title css={{ marginBottom: '2px' }}>
                {user?.name ?? truncateStringCenter(4, auth.publicKey)}
              </Title>
              {user?.username && (
                <Subtitle css={{ marginBottom: 0 }}>
                  {formatUsername(user.username)}
                </Subtitle>
              )}
            </Box>
          </DropdownMenu.Item>
          <BalancesContainer>
            {auth.isSupportedChain ? (
              <>
                <BalancesItem>
                  <Subtitle lineHeight={0}>
                    Balance {getNetworkStatusBadge()}
                  </Subtitle>
                  {!auth.balance ? (
                    <BalancesLoader />
                  ) : (
                    <BalanceTitleWithChainIcon
                      balance={auth.balance}
                      chainId={auth.chainId}
                    />
                  )}
                </BalancesItem>
                {marketBalancesQuery.data &&
                  isNonZero(marketBalancesQuery.data.availableFethBalance) && (
                    <DropdownMenuItemWrapper>
                      <DropdownMenu.Item
                        onClick={() =>
                          setModal({ type: 'MARKETPLACE_BALANCE' })
                        }
                      >
                        <Box>
                          <Subtitle>Marketplace Balance</Subtitle>
                          <BalanceTitleWithChainIcon
                            balance={
                              marketBalancesQuery.data.availableFethBalance
                            }
                            chainId={auth.chainId}
                          />
                        </Box>
                      </DropdownMenu.Item>
                    </DropdownMenuItemWrapper>
                  )}
                {isBaseChainConfig(chainConfig) && (
                  <DropdownMenuItemWrapper>
                    <BalancesItem>
                      <Button
                        as="a"
                        href={chainConfig.bridge}
                        css={{ width: '100%' }}
                        target="_blank"
                        rel="noreferrer"
                        variant="outline"
                        icon
                        size={0}
                      >
                        <span>Bridge ETH to Base</span>
                        <ExternalIcon />
                      </Button>
                    </BalancesItem>
                  </DropdownMenuItemWrapper>
                )}
              </>
            ) : (
              <>
                <BalancesItem>
                  <Subtitle lineHeight={0}>
                    Balance {getNetworkStatusBadge()}
                  </Subtitle>
                  <BalancesLoader />
                </BalancesItem>
              </>
            )}
          </BalancesContainer>
          {worldId && (
            <HideOnDesktop>
              <DropdownMenu.Item
                type="link"
                href={getPath.world.dashboard(worldId)}
                onClick={() =>
                  analytics.track({
                    name: 'clicked_nav_dashboard',
                    hasDot: hasWorldsNotifications,
                  })
                }
              >
                <GridIcon /> Dashboard
                {hasWorldsNotifications && <StatusDot color="red" />}
              </DropdownMenu.Item>
            </HideOnDesktop>
          )}
          <HideOnDesktop>
            <DropdownMenu.Item type="link" href="/activity">
              <ActivityIcon />
              Activity
              {typeof activityCount === 'number' && activityCount > 0 && (
                <StatusDot css={{ marginLeft: '-$1' }} color="red" />
              )}
            </DropdownMenu.Item>
          </HideOnDesktop>
          <DropdownMenu.Item type="link" href="/notifications">
            <NotificationsIcon />
            Notifications
            {typeof unreadCount === 'number' && unreadCount > 0 && (
              <StatusDot css={{ marginLeft: '-$1' }} color="red" />
            )}
          </DropdownMenu.Item>

          <DropdownMenu.Item type="link" href="/settings">
            <SettingsIcon />
            Settings
          </DropdownMenu.Item>
          <DropdownMenu.Item href={HELP_CENTER_HOME} type="external-link">
            <HelpIcon />
            Help
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onClick={() => {
              disconnectWallet();
            }}
          >
            <DisconnectIcon />
            Disconnect
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

function BalanceTitleWithChainIcon(props: {
  balance: bigint;
  chainId: ChainId;
}) {
  return (
    <BalanceTitleContainer>
      {match(props.chainId)
        .with(CHAINS.base.chainId, CHAINS.baseSepolia.chainId, () => {
          return <BaseIcon />;
        })
        .otherwise(() => {
          return <EthereumIcon />;
        })}
      <Title>{formatBigIntEthWithSuffix(props.balance)}</Title>
    </BalanceTitleContainer>
  );
}

const BalancesContainer = styled('div', {
  marginY: '$2',
  boxShadow: 'none',
  overflow: 'hidden',
  borderRadius: '$2',
  border: '1px solid $black10',
});

const BalancesItem = styled('div', {
  paddingY: '$3',
  paddingLeft: '$4',
  paddingRight: '$3',
  borderBottom: '1px solid $black10',

  '&:only-child': {
    borderBottom: 'none',
  },
});

const Subtitle = styled(Text, {
  display: 'flex',
  marginBottom: '$1',
  alignItems: 'center',
  justifyContent: 'space-between',
});

Subtitle.defaultProps = {
  size: 1,
  ellipsis: true,
  lineHeight: 1,
  weight: 'semibold',
  color: 'dim',
};

const BalanceTitleContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
});

const Title = styled(Text, {
  minWidth: 0,
});
Title.defaultProps = {
  size: 3,
  weight: 'semibold',
  lineHeight: 1,
  ellipsis: true,
};

const BalancesLoader = styled(Skeleton.Block, {
  width: 80,
  height: 25,
  transition: 'background-color $1 $ease',
});

const DropdownMenuItemWrapper = styled('div', {
  // Targets DropdownMenu.Item
  '> div': {
    borderRadius: 0,
  },
  '@hover': {
    '&:hover': {
      [`${BalancesLoader}`]: {
        backgroundColor: '$black10',
      },
    },
  },
});

const NetworkStatus = styled('div', {
  paddingY: '$1',
  paddingX: '$2',
  borderRadius: '$1',
  backgroundColor: '$black5',
  fontFamily: '$body',
  fontSize: '$0',
  border: 'none',
  lineHeight: '12px',

  gap: '$2',
  display: 'flex',
  alignItems: 'center',
  fontWeight: '$regular',

  '@hover': {
    '&:hover': {
      backgroundColor: '$black10',
    },
  },

  [`${StatusDot}`]: {
    marginLeft: '$1',
  },

  variants: {
    mono: {
      true: {
        color: '$black100',
        fontFamily: '$mono',
      },
    },
  },
});

const HideOnDesktop = styled('div', {
  '@bp2': {
    display: 'none',
  },
});
