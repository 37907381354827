import { styled } from '@f8n-frontend/stitches';

import Box from './Box';

const Body = styled(Box, {
  maxWidth: '$container',
  marginLeft: 'auto',
  marginRight: 'auto',
  flexShrink: 0,
  flex: 1,
  flexGrow: 1,
  paddingX: '$4',
  width: '100%',

  '@bp2': {
    paddingX: '$8',
  },
});

export default Body;
