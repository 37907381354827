import { createNullCache } from '@algolia/cache-common';
import algoliasearch from 'algoliasearch/lite';

// TODO: move process.env values into utils/env
const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY as string,
  /*
   * by default algolia uses a cache
   * however as we are using react-query for caching we can disable them
   * to avoid issues with refetching data from algolia (hitting the cache)
   */
  {
    responsesCache: createNullCache(),
    requestsCache: createNullCache(),
  }
);

export default searchClient;
