import { match } from 'ts-pattern';

import { CuratedStores, CuratedStore } from 'types/CuratedStore';
import { WorldOverview } from 'types/World';

export const getTakeRateFromCuratedStore = (
  selectedCuratedStore: CuratedStore | null
): number => {
  return match(selectedCuratedStore)
    .when(isMomentCuratedStore, ({ moment }) => moment.takeRateInBasisPoints)
    .when(isWorldCuratedStore, ({ world }) => world.takeRateInBasisPoints)
    .otherwise(() => 0);
};

export const getWorldIdFromCuratedStore = (
  selectedCuratedStore: CuratedStore | null
): number => {
  return match(selectedCuratedStore)
    .when(isMomentCuratedStore, ({ moment }) => moment.worldId)
    .when(isWorldCuratedStore, ({ world }) => world.id)
    .otherwise(() => 0);
};

export const getMomentIdFromCuratedStore = (
  selectedCuratedStore: CuratedStore | null
): number | null => {
  return match(selectedCuratedStore)
    .when(isMomentCuratedStore, ({ moment }) => moment.id)
    .otherwise(() => null);
};

export const getWorldFromCuratedStore = (
  selectedCuratedStore: CuratedStore
): WorldOverview => {
  return match(selectedCuratedStore)
    .when(isMomentCuratedStore, ({ moment }) => moment.world)
    .when(isWorldCuratedStore, ({ world }) => world)
    .exhaustive();
};

export const isMomentCuratedStore = (
  selectedCuratedStore: CuratedStore | null
): selectedCuratedStore is Extract<CuratedStore, { type: 'MOMENT' }> => {
  return Boolean(
    selectedCuratedStore && selectedCuratedStore.type === 'MOMENT'
  );
};

export const isWorldCuratedStore = (
  selectedCuratedStore: CuratedStore | null
): selectedCuratedStore is Extract<CuratedStore, { type: 'WORLD' }> => {
  return Boolean(selectedCuratedStore && selectedCuratedStore.type === 'WORLD');
};

export const getCuratedStorePrepareEnabled = (options: {
  selectedCuratedStore: CuratedStore | null;
  query: { isSuccess: boolean };
}): boolean => {
  if (!isMomentCuratedStore(options.selectedCuratedStore)) {
    return true;
  }

  return options.query.isSuccess;
};

export const hasCuratedStores = (curatedStores: CuratedStores) => {
  return curatedStores.worlds.length > 0 || curatedStores.moments.length > 0;
};
