import Grid from 'components/base/Grid';
import Heading from 'components/base/Heading';

interface ResultSectionProps {
  children: React.ReactNode;
  title: string;
  visible: boolean;
}

export default function ResultSection(props: ResultSectionProps) {
  const { children, title, visible } = props;

  if (!visible) {
    return null;
  }

  return (
    <Grid css={{ gap: '$2' }}>
      <Heading
        color="dim"
        css={{ paddingLeft: '$3' }}
        size={1}
        weight="semibold"
      >
        {title}
      </Heading>
      <Grid css={{ gap: 0 }}>{children}</Grid>
    </Grid>
  );
}
