import NextLink from 'next/link';

import Logo from 'components/Logo';
import Link from 'components/base/Link';

import { UnsafeAny } from 'types/utils';

interface LogoLinkProps {
  color: string;
  css?: UnsafeAny;
}

export default function LogoLink(props: LogoLinkProps) {
  const { color, css } = props;

  return (
    <NextLink href="/" passHref>
      <Link
        css={{
          color,
          display: 'block',
          transition: 'opacity $1 $ease',
          '@hover': {
            '&:hover': {
              opacity: 0.85,
            },
          },
        }}
        aria-label="Foundation logo"
        className="logo-link"
      >
        <Logo
          css={{
            width: 42,
            ...css,
          }}
        />
      </Link>
    </NextLink>
  );
}

export function FooterLogoLink() {
  return (
    <LogoLink
      css={{
        width: 65,
        '@bp0': {
          width: 65,
        },
      }}
      color="$black15"
    />
  );
}
