import { styled } from '@f8n-frontend/stitches';
import { ReactNode } from 'react';

import Body from 'components/base/Body';
import Grid from 'components/base/Grid';
import Heading from 'components/base/Heading';
import Text from 'components/base/Text';

import { ModeratedModerationStatus } from 'types/Moderation';

import ModerationIcon from './ModerationIcon';

interface WarningBlockProps {
  description: ReactNode;
  status: ModeratedModerationStatus;
  title: string;
}

export default function WarningBlock<T extends WarningBlockProps>(props: T) {
  const { description, status, title } = props;

  return (
    <Container>
      <Grid css={{ gap: '$6' }}>
        <ModerationIcon status={status} />
        <Copy>
          <Heading size={5} lineHeight={1} css={{ maxWidth: 480 }}>
            {title}
          </Heading>
          <Text color="dim" css={{ maxWidth: 450 }} lineHeight={3}>
            {description}
          </Text>
        </Copy>
      </Grid>
    </Container>
  );
}

const Container = styled(Body, {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  svg: {
    marginX: 'auto',
    color: '$black50',
    display: 'block',
    width: 44,
    height: 44,
  },
});

const Copy = styled(Grid, {
  gap: '$2',
  textAlign: 'center',
  '> *': {
    marginX: 'auto',
  },
});
